// eslint-disable-next-line import/no-cycle
import axios from './axios';

export function formQuery(options) {
  if (!options) return '';
  let result = '?';

  Object.keys(options).forEach((key) => {
    const type = typeof options[key];
    let paramBody = '';
    switch (type) {
      case 'object':
        paramBody = options[key].paramBody || 'notEquals';
        break;
      case 'boolean':
        paramBody = 'specified';
        break;
      case 'string':
      case 'number':
        paramBody = 'equals';
        break;

      default:
        break;
    }

    result += paramBody
      ? `${key}.${paramBody}=${encodeURIComponent(options[key]?.paramValue || options[key]?.id || options[key])}&`
      : '';
  });
  return result;
}

export function deleteApiData(url) {
  return axios.delete(url);
}

export function getApiData(url) {
  return axios.get(url);
}

export function postApiData(url, body, options = {}) {
  return axios.post(url, body, {
    headers: {
      'Content-type': 'application/json',
    },
    ...options,
  });
}

export function patchApiData(url, body, options = {}) {
  return axios.patch(url, body, {
    headers: {
      'Content-type': 'application/json',
    },
    ...options,
  });
}

export function putApiData(url, body, options = {}) {
  return axios.put(url, body, {
    headers: {
      'Content-type': 'application/json',
    },
    ...options,
  });
}

export function fileUpload(url, formData) {
  return axios.post(url, formData, {
    headers: {
      'Content-type': 'multipart/form-data',
    },
  });
}
