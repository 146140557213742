import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Backdrop, Box, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { toLonLat } from 'ol/proj';
import { useDispatch } from 'react-redux';
import DeliveryGeoMapEditorWidget from '../../../widgets/logistics/DeliveryGeoMapEditorWidget/DeliveryGeoMapEditorWidget';
import LogisticTaskBll from '../../../entities/logistic/logisticTasks/LogisticTaskBll/LogisticTaskBll';
import DeliveryMainWidget from "../../../widgets/logistics/DeliveryMainWidget/DeliveryMainWidget";
import DeliveryMainWidgetSecond from "../../../widgets/logistics/DeliveryMainWidget/DeliveryMainWidgetSecond";
import { useAppSelector } from '../../../app/store';
import reducerPath from '../../../app/reducerPath';
import { mapNames } from '../../../entities/map/fullScreenMap/redux/fullScreenMap.slice';
import useShowError from '../../../shared/lib/hooks/useShowError';
import Loading from '../../../shared/ui/Loading/Loading';
import { useCreateVehicleRoutesMutation, useReorderLogisticTaskMutation } from '../../../entities/logistic/logisticTasks/redux/logisticTasks.api';
import {
  LogisticTasksEnum,
  ROUTE_NAME_TPLS,
  logisticTasksResourceActions,
  transformData,
} from '../../../entities/logistic/logisticTasks/redux/logisticTasks.slice';
import FormDialog from '../../../shared/ui/FormDialog/FormDialog';

const parseCoords = (coordinates) => {
  if (!coordinates) {
    return null;
  }
  const lonLat = toLonLat(coordinates);
  return { lon: lonLat[0], lat: lonLat[1] };
};

function DeliveryMainPanelBll(props) {
  const {
    logisticTask,
    patchLogisticTask,
    patchLogisticTaskSolve,
    solveLogisticTask,
    resultPatchLogisticTask,
  } = props;
  const {
    data,
    data: { name: taskName },
    routeNameTemplate,
    rePlanDate,
  } = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);
  const isFullscreen = useAppSelector((state) => state[`${reducerPath.fullScreenMap}`])[mapNames.logisticDelivery];
  const showError = useShowError();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLogisticTaskPristine = useAppSelector((state) => {
    const store = state[`${reducerPath.logisticTasksResource}/counter`];
    // TODO Проверить формат поля rePlanDate (startTime?)
    return store[LogisticTasksEnum.data] === store[LogisticTasksEnum.initialData]
      && (rePlanDate ? rePlanDate === logisticTask?.data?.startTime
        : !logisticTask?.data?.startTime);
  });

  const [
    reorderLogisticTask,
    reorderLogisticTaskResult,
  ] = useReorderLogisticTaskMutation();

  const [
    createVehicleRoutes,
    createVehicleRoutesResult,
  ] = useCreateVehicleRoutesMutation();

  useEffect(() => {
    dispatch(logisticTasksResourceActions.setRePlanDate((
      logisticTask?.data?.startTime || null
    )));
  }, [logisticTask]);

  useEffect(() => {
    if (createVehicleRoutesResult.status === 'fulfilled') {
      navigate(`/companies/${logisticTask.data.companyId}/routes`);
    }
  }, [createVehicleRoutesResult.status]);

  useEffect(() => {
    if (reorderLogisticTaskResult.status === 'fulfilled') {
      const { data: payload } = reorderLogisticTaskResult;
      const dataWithMissingRoutes = {
        ...payload,
        solution: {
          ...logisticTask.data.solution,
          // eslint-disable-next-line react/prop-types
          routes: logisticTask.data.solution.routes.map((r) => {
            const newRoute = payload.solution.routes.find((route) => (
              route.vehicleDetails.registrationNumber === r.vehicleDetails.registrationNumber
            ));
            return newRoute || r;
          })
        },
      };
      const newData = transformData(dataWithMissingRoutes);
      dispatch(logisticTasksResourceActions.setData({ data: newData }));
    }
  }, [reorderLogisticTaskResult.status]);

  const [showReordering, setShowReordering] = React.useState(false);
  const [isOpenCreateVehicleRouteDialog, setIsOpenCreateVehicleRouteDialog] = React.useState(false);

  const onCLickSave = () => {
    const orders = [...JSON.parse(JSON.stringify(logisticTask.data.source.orderSourceList))];
    const points = JSON.parse(JSON.stringify(data.sourceWithSolution.points));
    const keys = Object.keys(points);

    // Перебираем измененные коодинаты точек заказов, сохраняем в источник
    const orderSourceList = keys.reduce((prev, key) => {
      const point = points[key];
      const newPrev = [...prev];
      if (point.isEdited) {
        const index = orders.findIndex((order) => order.id === point.orderNumber);
        const coords = parseCoords(point.coordinates);
        if (point.isLoading) {
          newPrev[index] = {
            ...newPrev[index],
            loadingLon: coords?.lon,
            loadingLat: coords?.lat,
          };
        }
        if (point.isUnloading) {
          newPrev[index] = {
            ...newPrev[index],
            deliveryLon: coords?.lon,
            deliveryLat: coords?.lat,
          };
        }
      }
      return newPrev;
    }, orders);
    // TODO Проверить актуальный формат сохранения поля rePlanDate (startTime?)
    const body = {
      name: data?.name || "",
      startTime: rePlanDate || "",
      logisticTaskId: logisticTask.data.logisticTaskId,
      source: {
        ...logisticTask.data.source,
        orderSourceList,
      },
      solution: data?.solution || []
    };
    return patchLogisticTask({ body }).catch(() => showError('Ошибка при сохранении данных.'));
  };

  const onCLickOptimize = async () => {
    await onCLickSave();
    patchLogisticTaskSolve()
      .catch(() => showError('Ошибка при оптимизации решения.'));
  };

  const onCLickCreateRoutes = async () => {
    setIsOpenCreateVehicleRouteDialog(true);
  };

  const processCreateRoutes = async () => {
    const { data: newData } = await onCLickSave();
    await createVehicleRoutes({
      body: newData,
      rePlanDate,
      routeNameTemplate,
      taskName,
    }).catch(() => showError('Ошибка при создании маршрутов.'));
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={solveLogisticTask.isLoading || resultPatchLogisticTask.isLoading
          || reorderLogisticTaskResult.isLoading}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress color="inherit" />
          <Typography variant="body1">
            {solveLogisticTask.isLoading
              ? 'Оптимизация...'
              : 'Сохранение...'}
          </Typography>
        </Box>
      </Backdrop>
      <FormDialog
        disableTextField
        open={isOpenCreateVehicleRouteDialog}
        onSave={processCreateRoutes}
        handleClose={() => {
          setIsOpenCreateVehicleRouteDialog(false);
          dispatch(logisticTasksResourceActions.setRouteNameTemplate(ROUTE_NAME_TPLS[0].value));
        }}
        dialogContent={(
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="route-name-tpl">Шаблон имени маршрута</InputLabel>
            <Select
              id="route-name-tpl"
              labelId="route-name-tpl"
              value={routeNameTemplate}
              onChange={(evt) => (
                dispatch(logisticTasksResourceActions.setRouteNameTemplate(evt.target.value))
              )}
              label="Шаблон имени маршрута"
            >
              {ROUTE_NAME_TPLS.map(({ value, label }) => (
                <MenuItem key={value} value={value}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        title="Создание маршрутов"
        saveTitle="Создать"
        cancelTitle="Отмена"
      />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: isFullscreen ? 'column' : 'row',
            gap: '10px',
          }}
        >
          <DeliveryGeoMapEditorWidget logisticTask={logisticTask} />
          {logisticTask?.isLoading
            ? (
              <Loading />
            )
            : (
              <Box>
                <DeliveryMainWidget />
                <DeliveryMainWidgetSecond
                  showReordering={showReordering}
                  setShowReordering={setShowReordering}
                  reorderLogisticTask={reorderLogisticTask}
                />
              </Box>
            )}
        </Box>
        <Box
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
            display: 'flex',
            gap: '20px',
          }}
        >
          <Button
            variant="contained"
            onClick={onCLickSave}
            disabled={!!logisticTask?.isLoading || isLogisticTaskPristine}
          >
            Сохранить
          </Button>
          <Button
            variant="contained"
            onClick={onCLickOptimize}
            disabled={!!logisticTask?.isLoading}
          >
            Оптимизировать
          </Button>
          <Button
            variant="contained"
            onClick={onCLickCreateRoutes}
            disabled={!!logisticTask?.isLoading}
          >
            Создать маршруты
          </Button>
        </Box>
      </Box>
    </>
  );
}

// Отображает панель "Доставка"
function DeliveryMainPanel() {
  const params = useParams();
  const logisticTaskId = parseInt(params.logisticTaskId, 10);

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '10px',
        flexDirection: 'column',
      }}
    >
      <Typography variant="subtitle1" component="div" sx={{ color: 'text.secondary' }}>
        Построение маршрутов для перевозки заказов
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: '10px',
        }}
      >
        <LogisticTaskBll
          logisticTaskId={logisticTaskId}
        >
          <DeliveryMainPanelBll />
        </LogisticTaskBll>
      </Box>
    </Box>
  );
}

DeliveryMainPanelBll.propTypes = {
  logisticTask: PropTypes.shape({
    data: PropTypes.shape({
      startTime: PropTypes.string,
      logisticTaskId: PropTypes.number.isRequired,
      source: PropTypes.shape({
        vehicleDetailsList: PropTypes.arrayOf(
          PropTypes.shape({
            registrationNumber: PropTypes.string,
            liftingCapacity: PropTypes.number,
            maxWeightPercents: PropTypes.number,
            availableCargoCount: PropTypes.number,
            maxCargoCountPercents: PropTypes.number,
            spaceLength: PropTypes.number,
            spaceWidth: PropTypes.number,
            spaceHeight: PropTypes.number,
            maxVolumePercents: PropTypes.number,
            vehicleProperties: PropTypes.string,
            excludedProperties: PropTypes.string,
            start: PropTypes.string,
            startLat: PropTypes.number,
            startLon: PropTypes.number,
            finishLat: PropTypes.number,
            finishLon: PropTypes.number,
            firstShiftWorkTimeFrom: PropTypes.string,
            firstShiftWorkTimeTo: PropTypes.string,
            isFirstShiftHardTime: PropTypes.bool,
            maxCargoCount: PropTypes.number,
            firstShiftHardTime: PropTypes.bool,
          })
        ),
        orderSourceList: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            additionalNum: PropTypes.string,
            deliveryLat: PropTypes.number,
            deliveryLon: PropTypes.number,
            clientName: PropTypes.string,
            deliveryAddress: PropTypes.string,
            loadingLat: PropTypes.number,
            loadingLon: PropTypes.number,
            clientPhone: PropTypes.string,
            timeWindowFrom: PropTypes.string,
            timeWindowTo: PropTypes.string,
            sharedServiceDuration: PropTypes.number,
            serviceDuration: PropTypes.number,
            weight: PropTypes.number,
            cargoCount: PropTypes.number,
            width: PropTypes.number,
            length: PropTypes.number,
            height: PropTypes.number,
          })
        )
      }),
      companyId: PropTypes.number,
      solution: PropTypes.shape({
        trackPoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
        })),
        routePoints: PropTypes.arrayOf(PropTypes.shape({
          lat: PropTypes.number,
          lon: PropTypes.number,
          arriveTime: PropTypes.string,
          leaveTime: PropTypes.string,
          orderNum: PropTypes.number,
        })),
        totalTime: PropTypes.number,
      })
    }),
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  patchLogisticTask: PropTypes.func,
  patchLogisticTaskSolve: PropTypes.func,
  solveLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
  resultPatchLogisticTask: PropTypes.shape({
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
  }),
};

DeliveryMainPanelBll.defaultProps = {
  logisticTask: null,
  solveLogisticTask: null,
  resultPatchLogisticTask: null,
  patchLogisticTask: () => {},
  patchLogisticTaskSolve: () => {},
};

export default DeliveryMainPanel;
