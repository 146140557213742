import React, {
  useEffect,
  useState
} from 'react';
import {
  Alert, AlertTitle, Autocomplete, Box, Button, FormControl, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import style from './CompanyBalanceEditPanel.module.css';
import { getCompanies } from "../../../shared/api/api";
import {
  CompanyAccountResourceType
} from "../../../entities/account/companyAccountResource/redux/companyAccountResource.slice";
import { checkStringLength, types } from "../CompanyBalanceCreatePanel/CompanyBalanceCreatePanel";
// eslint-disable-next-line boundaries/element-types
import CompanyFinancePanel from "../../companies/CompanyFinancePanel/CompanyFinancePanel";

// Отображает панель редактирования Баланс компаний
function CompanyBalanceEditPanel({
  dataCompanyAccount,
  fetchGetCompanyAccountResourceIdParams,
  fetchPatchCompanyAccountResourceParams
}) {
  const paramsPage = useParams();
  const [isErr, setIsErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [errors, setErrors] = useState({});
  const [companyAccountApi, setCompanyAccountApi] = useState([]);
  // тип
  const [type, setType] = useState(null);
  // название контрагента
  const [nameCounterparty, setNameCounterparty] = useState();
  // адрес контрагента
  const [counterpartyAddress, setCounterpartyAddress] = useState();
  // инн контрагента
  const [counterpartyINN, setCounterpartyINN] = useState();
  // кпп контрагента
  const [counterpartyKPP, setCounterpartyKPP] = useState();
  // огрн контрагента
  const [counterpartyOGRN, setCounterpartyOGRN] = useState();
  // ОКТМО контрагента
  const [counterpartyOKTMO, setCounterpartyOKTMO] = useState();
  // телефон контрагента
  const [counterpartyPhone, setCounterpartyPhone] = useState();
  // имя ответственного лица контрагента
  const [counterpartyNameMain, setCounterpartyNameMain] = useState();
  // рассчетный счет контрагента
  const [counterpartyBill, setCounterpartyBill] = useState();
  // наименование банка контрагента
  const [counterpartyBankName, setCounterpartyBankName] = useState();
  // БИК контрагента
  const [counterpartyBik, setCounterpartyBik] = useState();
  // корр.счет контрагента
  const [counterpartyBankNumber, setCounterpartyBankNumber] = useState();
  // Компания
  const [company, setCompany] = useState();
  // компания с бека
  const [companiesApi, setCompaniesApi] = useState([]);
  // измененные данные доп. секций аккордеона
  const [additionalData, setAdditionalData] = useState({});

  const [isPristine, setIsPristine] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    if (dataCompanyAccount?.data) {
      getCompanies().then((companies) => {
        setCompaniesApi(companies);
      });
    }
  }, [dataCompanyAccount]);

  const handleSaveChanges = () => {
    const data = {
      ...companyAccountApi,
      type: types.find((e) => e.id === type).type,
      agentName: String(nameCounterparty),
      address: String(counterpartyAddress),
      inn: String(counterpartyINN),
      kpp: String(counterpartyKPP),
      ogrn: String(counterpartyOGRN),
      oktmo: String(counterpartyOKTMO),
      phone: String(counterpartyPhone),
      respName: String(counterpartyNameMain),
      rs: String(counterpartyBill),
      bankName: String(counterpartyBankName),
      bik: String(counterpartyBik),
      kors: String(counterpartyBankNumber),
      company,
      ...additionalData,
    };
    if (fetchPatchCompanyAccountResourceParams) {
      fetchPatchCompanyAccountResourceParams(data).then((e) => {
        if (e?.error) {
          setIsErr(true);
        } else {
          setIsCorrect(true);
          setIsPristine(true);
        }
      });
    }
  };

  useEffect(() => {
    if (nameCounterparty) {
      if (!checkStringLength(nameCounterparty, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          nameCounterparty: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          nameCounterparty: false
        }));
      }
    }

    if (counterpartyAddress) {
      if (!checkStringLength(counterpartyAddress, 1, 512)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyAddress: "Ошибка, максимальная длина поля – 512 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyAddress: false
        }));
      }
    }

    if (counterpartyINN && type !== 3) {
      if (!checkStringLength(counterpartyINN, type === 2 ? 12 : 10, type === 2 ? 12 : 10)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyINN: `${
            type === 2
              ? "ИНН индивидуального предпринимателья представляет собой последовательность из 12 арабских цифр"
              : "ИНН юридического лица представляет собой последовательность из 10 арабских цифр"}`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyINN: false
        }));
      }
    }

    if (counterpartyKPP && type === 1) {
      if (!checkStringLength(counterpartyKPP, 9, 9)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyKPP: "КПП представляет собой код из 9 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyKPP: false
        }));
      }
    }

    if (counterpartyOGRN && type !== 3) {
      if (!checkStringLength(counterpartyOGRN, type === 2 ? 15 : 13, type === 2 ? 15 : 13)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyOGRN: `${type === 2 ? "ОГРНИП представляет собой код из 15 арабских цифр"
            : "ОГРН представляет собой код из 13 арабских цифр"}`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyOGRN: false
        }));
      }
    }

    if (counterpartyOKTMO) {
      if (!checkStringLength(counterpartyOKTMO, 8, 8)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyOKTMO: `ОКТМО представляет собой код из 8 арабских цифр`
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyOKTMO: false
        }));
      }
    }

    if (counterpartyPhone) {
      if (!checkStringLength(counterpartyPhone, 1, 16)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyPhone: "Ошибка, максимальная длина поля – 16 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyPhone: false
        }));
      }
    }

    if (counterpartyNameMain) {
      if (!checkStringLength(counterpartyNameMain, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyNameMain: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyNameMain: false
        }));
      }
    }

    if (counterpartyBill) {
      if (!checkStringLength(counterpartyBill, 20, 20)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBill: "Номер расчетного счета состоит из 20 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBill: false
        }));
      }
    }

    if (counterpartyBankName) {
      if (!checkStringLength(counterpartyBankName, 1, 256)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankName: "Ошибка, максимальная длина поля – 256 символов"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankName: false
        }));
      }
    }

    if (counterpartyBik) {
      if (!checkStringLength(counterpartyBik, 9, 9)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBik: "БИК состоит из 9 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBik: false
        }));
      }
    }

    if (counterpartyBankNumber) {
      if (!checkStringLength(counterpartyBankNumber, 20, 20)) {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankNumber: "Корр.счет состоит из 20 арабских цифр"
        }));
      } else {
        setErrors((prev) => ({
          ...prev,
          counterpartyBankNumber: false
        }));
      }
    }
  }, [nameCounterparty, counterpartyAddress, counterpartyINN,
    counterpartyKPP, counterpartyOGRN, counterpartyOKTMO, counterpartyPhone,
    counterpartyNameMain, counterpartyBill, counterpartyBankName, counterpartyBik,
    counterpartyBankNumber, type]);

  useEffect(() => {
    if (companiesApi.length) {
      fetchGetCompanyAccountResourceIdParams(paramsPage.tarifPlanId).then((e) => {
        const data = e?.data?.data;
        setCompanyAccountApi(data);
        setType(types.find((item) => item.type === data.type)?.id);
        setCounterpartyBankNumber(data.kors);
        setCounterpartyBik(data.bik);
        setCounterpartyBankName(data.bankName);
        setCounterpartyBill(data.rs);
        setCounterpartyNameMain(data.respName);
        setCounterpartyPhone(data.phone);
        setCounterpartyOKTMO(data.oktmo);
        setCounterpartyOGRN(data.ogrn);
        setCounterpartyKPP(data.kpp);
        setCounterpartyINN(data.inn);
        setCounterpartyAddress(data.address);
        setNameCounterparty(data.agentName);
        setCompany(companiesApi.find((item) => item.companyId === data.company.companyId));
        setIsPristine(true);
      });
    }
  }, [paramsPage, companiesApi]);

  const isError = (errors.nameCounterparty
      || errors.counterpartyAddress
      || errors.counterpartyINN
      || errors.counterpartyKPP
      || errors.counterpartyOGRN
      || errors.counterpartyOKTMO
      || errors.counterpartyPhone
      || errors.counterpartyNameMain
      || errors.counterpartyBill
      || errors.counterpartyBankName
      || errors.counterpartyBik
      || errors.counterpartyBankNumber
      || !nameCounterparty
      || !counterpartyAddress
      || (type !== 3 ? !counterpartyINN : false)
      || (type === 1 ? !counterpartyKPP : false)
      || (type !== 3 ? !counterpartyOGRN : false)
      || !counterpartyOKTMO
      || !counterpartyPhone
      || !counterpartyNameMain
      || !counterpartyBill
      || !counterpartyBankName
      || !counterpartyBik
      || !counterpartyBankNumber
      || !company
  );

  useEffect(() => {
    setIsCorrect(false);
    setIsErr(false);
  }, [nameCounterparty,
    counterpartyAddress,
    counterpartyINN,
    counterpartyKPP,
    counterpartyOGRN,
    counterpartyOKTMO,
    counterpartyPhone,
    counterpartyNameMain,
    counterpartyBill,
    counterpartyBankName,
    counterpartyBik,
    counterpartyBankNumber,
    additionalData,
  ]);

  const setWithTouch = (method, value) => {
    method(value);
    setIsPristine(false);
  };

  return (
    <Box className={style.UsersInfoPanel}>
      <Accordion sx={{ p: 1, width: "100%" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Основная информация</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box>
            <Box sx={{
              display: "grid",
              gridTemplateColumns: "repeat(3, 1fr)",
              width: "100%",
              gap: "30px",
              marginBottom: '30px',
            }}
            >
              <FormControl fullWidth>
                <InputLabel id="category-label">Тип</InputLabel>
                <Select
                  labelId="category-label"
                  id="category-label"
                  value={type || 0}
                  label="Тип"
                  onChange={(e) => {
                    setCounterpartyBankNumber(null);
                    setCounterpartyBik(null);
                    setCounterpartyBankName(null);
                    setCounterpartyBill(null);
                    setCounterpartyNameMain(null);
                    setCounterpartyPhone(null);
                    setCounterpartyOKTMO(null);
                    setCounterpartyOGRN(null);
                    setCounterpartyKPP(null);
                    setCounterpartyINN(null);
                    setCounterpartyAddress(null);
                    setNameCounterparty(null);
                    setCompany(null);
                    setType(e.target.value);
                    setIsPristine(false);
                  }}
                >
                  {types.map((typeCurrent, i) => (
                    <MenuItem
                      key={`typeCurrent-select-item${typeCurrent.id}-${i + 1}`}
                      value={typeCurrent.id}
                    >
                      {typeCurrent.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {type !== null && (
            <Box sx={{
              display: "flex",
              width: "100%",
              gap: "50px",
              flexWrap: "wrap",
            }}
            >
              {/* Название + Адреса */}
              <Box sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                width: "100%",
                gap: "30px"
              }}
              >
                <TextField
                  value={nameCounterparty || ''}
                  type="text"
                  onChange={(e) => setWithTouch(setNameCounterparty, e.target.value)}
                  sx={{ width: "100%" }}
                  label="ФИО контрагента"
                  variant="standard"
                  error={Boolean(errors.nameCounterparty)}
                  helperText={errors.nameCounterparty}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

                <TextField
                  value={counterpartyAddress || ''}
                  type="text"
                  onChange={(e) => setWithTouch(setCounterpartyAddress, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Адрес контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyAddress)}
                  helperText={errors.counterpartyAddress}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

                <TextField
                  value={counterpartyPhone || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyPhone, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Телефон контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyPhone)}
                  helperText={errors.counterpartyPhone}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

                <TextField
                  value={counterpartyNameMain || ''}
                  type="text"
                  onChange={(e) => setWithTouch(setCounterpartyNameMain, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Имя ответственного лица контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyNameMain)}
                  helperText={errors.counterpartyNameMain}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

              </Box>
              {/* Реквизиты ЮЛ/ИП/ФЛ */}
              <Box sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                width: "100%",
                gap: "30px"
              }}
              >
                <Autocomplete
                  style={{ width: "100%" }}
                  disablePortal
                  id="company"
                  options={companiesApi}
                  value={company}
                  onChange={(_, newValue) => setWithTouch(setCompany, newValue)}
                  isOptionEqualToValue={(option, value) => option.companyId === value.companyId}
                  getOptionLabel={(option) => option.name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Компания"
                    />
                  )}
                />

                {type !== 3
                        && (
                        <TextField
                          value={counterpartyINN || ''}
                          type="number"
                          onChange={(e) => setWithTouch(setCounterpartyINN, e.target.value)}
                          sx={{ width: "100%" }}
                          label="ИНН контрагента"
                          variant="standard"
                          error={Boolean(errors.counterpartyINN)}
                          helperText={errors.counterpartyINN}
                          onBlur={(event) => {
                            setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                          }}
                        />
                        )}

                {type === 1 && (
                <TextField
                  value={counterpartyKPP || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyKPP, e.target.value)}
                  sx={{ width: "100%" }}
                  label="КПП контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyKPP)}
                  helperText={errors.counterpartyKPP}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                )}
                {type !== 3
                        && (
                        <TextField
                          value={counterpartyOGRN || ''}
                          type="number"
                          onChange={(e) => setWithTouch(setCounterpartyOGRN, e.target.value)}
                          sx={{ width: "100%" }}
                          label={`${type === 1 ? "ОГРН" : "ОГРНИП"} контрагента`}
                          variant="standard"
                          error={Boolean(errors.counterpartyOGRN)}
                          helperText={errors.counterpartyOGRN}
                          onBlur={(event) => {
                            setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                          }}
                        />
                        )}

                <TextField
                  value={counterpartyOKTMO || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyOKTMO, e.target.value)}
                  sx={{ width: "100%" }}
                  label="ОКТМО контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyOKTMO)}
                  helperText={errors.counterpartyOKTMO}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

              </Box>
              {/* Банковские реквизиты (начинаем с названия банка) */}
              <Box sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                width: "100%",
                gap: "30px"
              }}
              >

                <TextField
                  value={counterpartyBill || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyBill, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Рассчетный счет контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyBill)}
                  helperText={errors.counterpartyBill}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
                <TextField
                  value={counterpartyBankName || ''}
                  type="text"
                  onChange={(e) => setWithTouch(setCounterpartyBankName, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Наименование банка контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyBankName)}
                  helperText={errors.counterpartyBankName}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

                <TextField
                  value={counterpartyBik || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyBik, e.target.value)}
                  sx={{ width: "100%" }}
                  label="БИК контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyBik)}
                  helperText={errors.counterpartyBik}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />

                <TextField
                  value={counterpartyBankNumber || ''}
                  type="number"
                  onChange={(e) => setWithTouch(setCounterpartyBankNumber, e.target.value)}
                  sx={{ width: "100%" }}
                  label="Корр.счет контрагента"
                  variant="standard"
                  error={Boolean(errors.counterpartyBankNumber)}
                  helperText={errors.counterpartyBankNumber}
                  onBlur={(event) => {
                    setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
                  }}
                />
              </Box>
            </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <CompanyFinancePanel
        company={company?.companyId ? company : null}
        onChangeCB={(data) => setWithTouch(setAdditionalData, { ...additionalData, ...data })}
      />
      {!isErr && isCorrect
            && (
            <Alert sx={{ width: "100%", marginBottom: 2 }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Аккаунт успешно сохранен
            </Alert>
            )}
      {isErr
            && (
            <Alert sx={{ width: "100%", marginBottom: 2 }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Произошла ошибка
            </Alert>
            )}
      <Box className={style.buttonsPanel}>
        <Button onClick={handleSaveChanges} disabled={isError || isPristine} variant="contained">Сохранить</Button>
        <Button variant="contained" onClick={() => navigate(`/finances/company-balance`)}>
          {!isErr && isCorrect ? 'Назад' : 'Отмена'}
        </Button>
      </Box>
    </Box>
  );
}

CompanyBalanceEditPanel.propTypes = {
  dataCompanyAccount: CompanyAccountResourceType,
  fetchGetCompanyAccountResourceIdParams: PropTypes.func,
  fetchPatchCompanyAccountResourceParams: PropTypes.func,
};

CompanyBalanceEditPanel.defaultProps = {
  dataCompanyAccount: null,
  fetchGetCompanyAccountResourceIdParams: null,
  fetchPatchCompanyAccountResourceParams: null,
};

export default CompanyBalanceEditPanel;
