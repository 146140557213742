function convertSecondsToISO8601(props) {
  let seconds = props;
  let formattedResult = "";

  if (seconds < 0) {
    formattedResult += "-";
    seconds = Math.abs(seconds); // Берем абсолютное значение секунд
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  formattedResult += `PT${hours}H${minutes}M${remainingSeconds}S`;

  return formattedResult;
}

export default convertSecondsToISO8601;
