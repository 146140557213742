import { Box } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import style from './CompanyFinancePanel.module.css';
// eslint-disable-next-line boundaries/element-types
import CompanyFinanceViewAdmin from "../../../views/companies/CompanyFinanceViewAdmin/CompanyFinanceViewAdmin";

// Отображает секции аккордеона "Баланс компаний"
function CompanyFinancePanel({ company, onChangeCB }) {
  return (
    <Box className={style.companyFinanceViewAdmin}>
      <CompanyFinanceViewAdmin companyId={company?.companyId} onChangeCB={onChangeCB} />
    </Box>
  );
}

CompanyFinancePanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  onChangeCB: PropTypes.func.isRequired,
};

CompanyFinancePanel.defaultProps = {
  company: null,
};

export default CompanyFinancePanel;
