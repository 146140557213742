import React from 'react';
import { Box, CircularProgress, Container } from '@mui/material';
import PropTypes from "prop-types";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CompanyPaymentsPanel from '../../../panels/companies/CompanyPaymentsPanel/CompanyPaymentsPanel';
import companyAccountsApi from '../../../entities/companies/companyAccounts/redux/companyAccounts.api';
import CompanyTariffPlanPanel from '../../../panels/companies/CompanyTariffPlanPanel/CompanyTariffPlanPanel';
import CompanyLimits from '../../../panels/companies/CompanyLimits/CompanyLimits';

// Отображает страницу "Финансы" по компнаии
function CompanyFinanceViewAdmin({ companyId, onChangeCB }) {
  const companyAccount = companyAccountsApi.useGetCompanyAccountsQuery({
    params: companyId
      ? {
        'companyId.equals': companyId,
      }
      : undefined
  });

  const loader = (
    <WrapperLoader>
      <CircularProgress />
    </WrapperLoader>
  );

  return (
    <Container
      component="main"
      style={{ padding: 0 }}
    >
      <Box sx={{
        display: "flex",
        flexWrap: "wrap",
        gap: "15px",
      }}
      >
        <Accordion sx={{ p: 1, width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>Тарифный план</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {companyAccount.isFetching
              ? loader : (
                <CompanyTariffPlanPanel
                  onChangeCB={(nextPlan) => onChangeCB({
                    nextPlan: nextPlan ? {
                      tarifPlanId: nextPlan.tarifPlanId
                    } : null
                  })}
                  companyAccount={companyAccount?.data?.[0]}
                  loading={!companyAccount?.data}
                />
              )}
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ p: 1, width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Платежи</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {companyAccount.isFetching
              ? loader : (
                <CompanyPaymentsPanel
                  companyAccount={companyAccount?.data?.[0]}
                />
              )}
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ p: 1, width: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography>Блокировка и оповещения</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {companyAccount.isFetching
              ? loader : (
                <CompanyLimits
                  companyAccount={companyAccount?.data?.[0]}
                  loading={!companyAccount?.data}
                  onChangeCB={onChangeCB}
                />
              )}
          </AccordionDetails>
        </Accordion>
      </Box>

    </Container>
  );
}

function WrapperLoader({ children }) {
  return (
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "20px"
    }}
    >
      {children}
    </div>
  );
}

WrapperLoader.propTypes = {
  children: PropTypes.element.isRequired,
};

CompanyFinanceViewAdmin.propTypes = {
  companyId: PropTypes.number.isRequired,
  onChangeCB: PropTypes.func.isRequired,
};

export default CompanyFinanceViewAdmin;
