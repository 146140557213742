import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { fromLonLat } from 'ol/proj';
import style from './CompanyMapPanel.module.css';
import GeoMapWithTiles from '../../../features/geo-map/GeoMapWithTiles';
import MarkerVectorLayer from '../../../shared/ui/GeoMap/MarkerVectorLayer';
import { getTrackPoints, getVehicles } from '../../../shared/api/api';
import { subscribe, unsubscribe } from '../../../shared/api/socket';

const transformCoords = (lon, lat) => fromLonLat([lon / 10000000, lat / 10000000]);

// Отображает вкладку "Карта" с картой и маркерами ТС
function CompanyMapPanel({ company, division }) {
  const [vehiclesCoords, setVehiclesCoords] = useState([]);

  // Загрузка ТС по компании
  useEffect(() => {
    let trackPointsSubscriber = null;
    let topic;
    if (company || division) {
      topic = `/topic/track-points/${company ? `company/${company?.companyId}` : `division/${division?.divisionId}`}`;
      getVehicles(company ? { companyId: company.companyId } : { divisionId: division?.divisionId })
        .then((vehicles) => {
          if (vehicles.length) {
            const newVCoords = [...vehicles];
            Promise.allSettled(vehicles.map((vehicle) => (
              getTrackPoints({
                vehicleId: vehicle.vehicleId,
                date: {
                  paramBody: 'greaterThan',
                  paramValue: new Date(
                    new Date().getTime() - (24 * 60 * 60 * 1000)
                  ).toJSON(),
                }
              })
            ))).then((res) => {
              res.forEach(({ status, value }) => {
                if (status === 'fulfilled' && value?.length) {
                  const vehicleIndex = newVCoords.findIndex((c) => (
                    value[0].vehicleId === c.vehicleId
                  ));
                  if (vehicleIndex !== -1) {
                    const coordinates = transformCoords(value[0].longitude, value[0].latitude);
                    newVCoords[vehicleIndex].coordinates = coordinates;
                  }
                }
              });
              return newVCoords;
            })
              .then((newData) => {
                setVehiclesCoords(newData);
                subscribe(topic, (newTrackPoints) => {
                  const newCoords = newTrackPoints
                    ? transformCoords(newTrackPoints.longitude, newTrackPoints.latitude)
                    : [];
                  setVehiclesCoords((prev) => {
                    const vehIndex = prev.findIndex(
                      (veh) => veh.vehicleId === newTrackPoints.vehicleId
                    );
                    const vehicleData = vehicles.find(
                      (v) => v.vehicleId === newTrackPoints.vehicleId
                    );
                    const newVehiclesCoords = [...prev];
                    newVehiclesCoords[vehIndex] = vehIndex === -1
                      ? prev[vehIndex]
                      : {
                        ...prev[vehIndex],
                        coordinates: [...newCoords],
                        num: newTrackPoints.routeName
                          ? `${vehicleData.num} (${newTrackPoints.routeName})` : vehicleData.num
                      };
                    return newVehiclesCoords;
                  });
                }).then((subscriber) => {
                  trackPointsSubscriber = subscriber;
                }).catch((error) => { throw error; });
              })
              .catch((error) => { throw error; });
          } else {
            setVehiclesCoords([]);
          }
        }).catch((error) => { throw error; });
    }
    return () => {
      unsubscribe(trackPointsSubscriber, topic);
    };
  }, [company, division]);

  return (
    <Box className={style.companyMapPanel}>
      <GeoMapWithTiles
        styles={style.geoMap}
        isOffButtonFollowMode
      >
        {vehiclesCoords.map((vehicle) => (
          <MarkerVectorLayer
            key={vehicle.vehicleId}
            coordinates={vehicle.coordinates}
            text={vehicle.num}
          />
        ))}
      </GeoMapWithTiles>
    </Box>
  );
}

CompanyMapPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  division: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyMapPanel.defaultProps = {
  company: null,
  division: null,
};

export default CompanyMapPanel;
