import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import {
  Alert, AlertTitle,
  Box, Button, CircularProgress,
  FormControl,
  InputLabel,
  MenuItem, Select,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Paper from '@mui/material/Paper';
import PropTypes from "prop-types";
import calculateTotalDistance from "../../../shared/utils/calculateTotalDistance";

function getWeekOfMonth(date) {
  const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const dayOfWeek = startOfMonth.getDay();
  const daysInMonth = endOfMonth.getDate();

  let week = 0;
  let weekDay = dayOfWeek;
  // eslint-disable-next-line no-plusplus
  for (let day = 1; day <= daysInMonth; day++) {
    if (weekDay === 0) {
      // eslint-disable-next-line no-plusplus
      week++;
    }
    if (day === date.getDate()) {
      break;
    }
    weekDay = (weekDay + 1) % 7;
  }

  return week + 1; // добавляем 1, так как неделя считается с 1, а не с 0
}

function addLeadingZero(number) {
  return String(number).padStart(2, '0');
}

function splitArrayByWeeks(dataArray) {
  const weeks = [[], [], [], [], [], []];

  dataArray.forEach((item) => {
    const date = new Date(item.date);
    const week = getWeekOfMonth(date);
    switch (week) {
      case 1:
        weeks[0].push(item);
        break;
      case 2:
        weeks[1].push(item);
        break;
      case 3:
        weeks[2].push(item);
        break;
      case 4:
        weeks[3].push(item);
        break;
      case 5:
        weeks[4].push(item);
        break;
      case 6:
        weeks[5].push(item);
        break;
      default:
        break;
    }
  });
  return weeks;
}

const months = [{ id: 1, name: "Январь" },
  { id: 2, name: "Февраль" },
  { id: 3, name: "Март" },
  { id: 4, name: "Апрель" },
  { id: 5, name: "Май" },
  { id: 6, name: "Июнь" },
  { id: 7, name: "Июль" },
  { id: 8, name: "Август" },
  { id: 9, name: "Сентябрь" },
  { id: 10, name: "Октябрь" },
  { id: 11, name: "Ноябрь" },
  { id: 12, name: "Декабрь" }];

const nowDate = new Date();

// const years = [{ id: 1, name: "2023" },
//   { id: 2, name: "2022" },
//   { id: 3, name: "2021" },
//   { id: 4, name: "2020" },
//   { id: 5, name: "2019" },
//   { id: 6, name: "2018" },
//   { id: 7, name: "2017" }];

const years = Array.from({ length: 10 })
  .map((_, index) => ({ id: index + 1, name: nowDate.getFullYear() - index }));

function createData(
  key,
  name,
  calories,
  fat,
  carbs,
  protein,
  cost,
  last
) {
  return {
    key, name, calories, fat, carbs, protein, cost, last
  };
}

const defaultDataMileage = [
  {
    week: 1,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
  {
    week: 2,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
  {
    week: 3,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
  {
    week: 4,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
  {
    week: 5,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
  {
    week: 6,
    odometerDist: 0,
    fuelCharge: 0,
    refuel: 0,
    personalDist: 0,
    workDist: 0,
    gpsDist: 0,
  },
];

function getMonthWeeks(year, month) {
  const weeks = [];
  const firstDate = new Date(year, month - 1, 1);
  const lastDate = new Date(year, month, 0);
  const firstDayOfWeek = firstDate.getDay();
  const lastDayOfFirstWeek = 7 - (firstDayOfWeek === 0 ? 6 : firstDayOfWeek - 1);

  let currentWeekStart = 1;
  let currentWeekEnd = lastDayOfFirstWeek;

  while (currentWeekStart <= lastDate.getDate()) {
    if (currentWeekEnd > lastDate.getDate()) {
      currentWeekEnd = lastDate.getDate();
    }

    weeks.push({ start: currentWeekStart, finish: currentWeekEnd });

    currentWeekStart = currentWeekEnd + 1;
    currentWeekEnd += 7;
  }

  return weeks;
}

// Отображает Данные ТС по пробегу
function ReportingPanel({
  postVehicleWeekMileagesResourceParams,
  getVehicleWeekMileagesParams,
  patchVehicleWeekMileagesResourceParams,
  getTrackPointsResourceParams,
  vehicle,
}) {
  const [month, setMonth] = useState(nowDate.getMonth() + 1);
  const [year, setYear] = useState(1);
  const [isCreateNewDataMileage, setIsCreateNewDataMileage] = useState(false);
  const disabledTable = useMemo(() => !!(month && year && vehicle), [month, year, vehicle]);
  const [mileageOfNewMonth, setMileageOfNewMonth] = useState(0);
  const [oilOfNewMonth, setOilOfNewMonth] = useState(0);
  const [dataMileage, setDataMileage] = useState(defaultDataMileage);
  const [isCorrect, setIsCorrect] = useState(false);
  const [isLoadingGps, setIsLoadingGps] = useState(false);
  const [weekNumbers, setWeekNumbers] = useState([]);

  const rows = [
    createData('fuelCharge', 'Заправлено', dataMileage[0].fuelCharge, dataMileage[1].fuelCharge, dataMileage[2].fuelCharge, dataMileage[3].fuelCharge, dataMileage[4].fuelCharge, dataMileage[5].fuelCharge),
    createData('refuel', 'Фактический расход', dataMileage[0].refuel, dataMileage[1].refuel, dataMileage[2].refuel, dataMileage[3].refuel, dataMileage[4].refuel, dataMileage[5].refuel),
    createData('oilNormal', 'Расчет бензина по норме', 0, 0, 0, 0, 0, 0),
    createData('personalDist', 'Личный пробег', dataMileage[0].personalDist, dataMileage[1].personalDist, dataMileage[2].personalDist, dataMileage[3].personalDist, dataMileage[4].personalDist, dataMileage[5].personalDist),
    createData('workDist', 'Рабочий пробег', dataMileage[0].workDist, dataMileage[1].workDist, dataMileage[2].workDist, dataMileage[3].workDist, dataMileage[4].workDist, dataMileage[5].workDist),
    createData('gpsDist', 'Проверочные показания по пробегу с GPS', dataMileage[0].gpsDist, dataMileage[1].gpsDist, dataMileage[2].gpsDist, dataMileage[3].gpsDist, dataMileage[4].gpsDist, dataMileage[5].gpsDist),
    createData('odometerDist', 'Счетчик пробега', dataMileage[0].odometerDist, dataMileage[1].odometerDist, dataMileage[2].odometerDist, dataMileage[3].odometerDist, dataMileage[4].odometerDist, dataMileage[5].odometerDist),
  ];

  const onChangeHandlerInputs2 = (e, row, number) => {
    const onChangeHandlerInputs = (id, currentId, object, key, value) => {
      const newObj = { ...object };
      if (id === currentId) {
        newObj[`${key}`] = value;
      }

      if ((key === "workDist" || key === 'personalDist')) {
        newObj.odometerDist = Number(newObj.workDist)
            + Number(newObj.personalDist)
            + Number(mileageOfNewMonth);
      }

      return newObj;
    };

    const arr = dataMileage.map((item) => onChangeHandlerInputs(
      item.week,
      dataMileage[number]?.week,
      item,
      row?.key,
      e?.target?.value,
    ));

    let mileage = 0;

    return arr.map((item, i) => {
      const newObj = { ...item };
      mileage = mileage + Number(newObj.workDist)
          + Number(newObj.personalDist)
          + (!i ? Number(mileageOfNewMonth) : 0);

      newObj.odometerDist = mileage;
      return newObj;
    });
  };

  const onSaveHandler = useCallback(() => {
    if (isCreateNewDataMileage) {
      dataMileage.map((e) => {
        const obj = {
          date: "2023-07-26T15:03:44.610Z",
          year: years[year - 1].name,
          month: months[month - 1].id,
          week: e.week,
          refuel: e.refuel,
          fuelCharge: e.fuelCharge,
          personalDist: e.personalDist,
          workDist: e.workDist,
          gpsDist: e.gpsDist,
          odometerDist: e.odometerDist,
          vehicle
        };

        return postVehicleWeekMileagesResourceParams({ body: obj }).then(() => {
          setIsCorrect(true);
        });
      });
    } else {
      dataMileage.map((e) => {
        const obj = {
          vehicleMileageId: e.vehicleMileageId,
          date: e.date,
          year: years[year - 1].name,
          month: months[month - 1].id,
          week: e.week,
          refuel: e.refuel,
          fuelCharge: e.fuelCharge,
          personalDist: e.personalDist,
          workDist: e.workDist,
          gpsDist: e.gpsDist,
          odometerDist: e.odometerDist,
          vehicle
        };
        return patchVehicleWeekMileagesResourceParams({ body: obj, id: e.vehicleMileageId })
          .then(() => {
            setIsCorrect(true);
          });
      });
    }
  },);

  useEffect(() => { setIsCorrect(false); }, [year, month, vehicle, dataMileage]);

  useEffect(() => {
    if (month && year && vehicle) {
      Promise.all(Array.from({ length: 6 }).map((_, i) => getVehicleWeekMileagesParams({
        params: {
          'vehicleId.equals': vehicle.vehicleId,
          'year.equals': years[year - 1].name,
          'month.equals': months[month - 1].id,
          'week.equals': i + 1,
        }
      }))).then((responses) => {
        const result = [];
        responses.forEach((e) => e.data.data.length && result.push(e.data.data[0]));
        if (result.length === 6) {
          const firstObj = result[0];
          setMileageOfNewMonth(firstObj.odometerDist - firstObj.workDist - firstObj.personalDist);
          setIsCreateNewDataMileage(false);
          setDataMileage(result);
        } else {
          setMileageOfNewMonth(0);
          setIsCreateNewDataMileage(true);
          setDataMileage(defaultDataMileage);
        }
      }).catch(() => {
        setDataMileage(defaultDataMileage);
        setIsCreateNewDataMileage(true);
        setMileageOfNewMonth(0);
      });
    }
  }, [month, year, vehicle]);

  useEffect(() => {
    setWeekNumbers(getMonthWeeks(years[year - 1].name, month));
  }, [year, month]);

  const gpsHandler = useCallback(() => {
    if (year && month && vehicle) {
      setIsLoadingGps(true);
      const dateStartCurrent = new Date(
        Number(years[year - 1].name),
        months[month - 1].id - 1,
        1,
        23,
        59,
        0
      );

      const dateFinishCurrent = new Date(
        Number(years[year - 1].name),
        months[month - 1].id,
        0,
        23,
        59,
        0
      );

      const localTimeZoneOffset = dateStartCurrent.getTimezoneOffset() * 60000;

      const isoDateStartCurrent = new Date(
        dateStartCurrent - localTimeZoneOffset
      ).toISOString();
      const isoDateFinishCurrent = new Date(
        dateFinishCurrent - localTimeZoneOffset
      ).toISOString();

      getTrackPointsResourceParams({
        params: {
          "vehicleId.equals": vehicle.vehicleId,
          "date.greaterThanOrEqual": isoDateStartCurrent,
          "date.lessThan": isoDateFinishCurrent,
        }
      }).then((e) => {
        if (e?.data?.data) {
          const arr = splitArrayByWeeks(e.data.data).map((item) => calculateTotalDistance(item));
          setDataMileage((prev) => prev.map((week, i) => {
            const newObj = { ...week };
            newObj.gpsDist = Math.round(arr[i]);
            return newObj;
          }));
        }
      }).finally(() => {
        setIsLoadingGps(false);
      });
    }
  }, [year, month, vehicle]);

  useEffect(() => {
    setDataMileage(
      onChangeHandlerInputs2()
    );
  }, [mileageOfNewMonth]);

  return (
    <Box sx={{ paddingBottom: "30px" }}>
      <Box sx={{ display: "flex", gap: "30px", flexWrap: "wrap" }}>
        <Box sx={{
          width: "100%", display: "flex", gap: "30px", marginTop: "30px"
        }}
        >
          <FormControl
            sx={{
              flex: "1",
              minWidth: "200px"
            }}
            fullWidth
          >
            <InputLabel id="typeMessage-label">Выберите год</InputLabel>
            <Select
              labelId="typeMessage-label"
              id="typeMessage-label"
              value={year || ''}
              label="Выберите год"
              onChange={((e) => {
                setYear(e.target.value);
              })}
            >
              {years?.map((yearCurrent) => (
                <MenuItem
                  key={`alertChannelId-select-item${yearCurrent.id}`}
                  value={yearCurrent.id}
                >
                  {yearCurrent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl
            sx={{
              flex: "1",
              minWidth: "200px"
            }}
            fullWidth
          >
            <InputLabel id="typeMessage-label">Выберите месяц</InputLabel>
            <Select
              labelId="typeMessage-label"
              id="typeMessage-label"
              value={month || 0}
              label="Выберите месяц"
              onChange={((e) => {
                setMonth(e.target.value);
              })}
            >
              {months?.map((monthCurrent) => (
                <MenuItem
                  key={`alertChannelId-select-item${monthCurrent.id}`}
                  value={monthCurrent.id}
                >
                  {monthCurrent.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ width: "100%" }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>{` `}</TableCell>
                  <TableCell align="center">
                    1 неделя
                    <br />
                    {addLeadingZero(weekNumbers[0]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[0]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  <TableCell align="center">
                    2 неделя
                    <br />
                    {addLeadingZero(weekNumbers[1]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[1]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  <TableCell align="center">
                    3 неделя
                    <br />
                    {addLeadingZero(weekNumbers[2]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[2]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  <TableCell align="center">
                    4 неделя
                    <br />
                    {addLeadingZero(weekNumbers[3]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[3]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  <TableCell align="center">
                    5 неделя
                    <br />
                    {addLeadingZero(weekNumbers[4]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[4]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  {weekNumbers[5]?.start && (
                  <TableCell align="center">
                    6 неделя
                    <br />
                    {addLeadingZero(weekNumbers[5]?.start)}
                    .
                    {addLeadingZero(month)}
                    {' '}
                    -
                    {' '}
                    {addLeadingZero(weekNumbers[5]?.finish)}
                    .
                    {addLeadingZero(month)}
                  </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow
                    key={row.name}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.calories : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 0)
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.fat : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 1)
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.carbs : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 2)
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.protein : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 3)
                        )}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.cost : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 4)
                        )}
                      />
                    </TableCell>
                    {weekNumbers[5]?.start && (
                    <TableCell align="right">
                      <TextField
                        disabled={(!disabledTable) || (row.key === 'oilNormal' || row.key === 'odometerDist' || row.key === 'gpsDist')}
                        value={disabledTable ? row.last : '-'}
                        type="number"
                        inputProps={{ min: 0, style: { textAlign: 'right' } }}
                        variant="outlined"
                        onChange={(e) => setDataMileage(
                          onChangeHandlerInputs2(e, row, 5)
                        )}
                      />
                    </TableCell>
                    )}

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box sx={{ width: "350px" }}>
          <TextField
            sx={{ width: "100%" }}
            value={mileageOfNewMonth || ''}
            type="number"
            onChange={(e) => {
              const { value } = e.target;
              setMileageOfNewMonth(value);
            }}
            label="Показания одометра на начало месяца"
            variant="outlined"
            disabled={!disabledTable}
          />
        </Box>
        <Box sx={{ width: "350px" }}>
          <TextField
            sx={{ width: "100%" }}
            value={oilOfNewMonth}
            type="number"
            disabled
            onChange={(e) => {
              const { value } = e.target;
              setOilOfNewMonth(value);
            }}
            label="Показания бензина на начало месяца"
            variant="outlined"
          />
        </Box>
        {isCorrect
            && (
            <Alert sx={{ width: "100%" }} severity="success">
              <AlertTitle>Успешно</AlertTitle>
              Данные успешно сохранены
            </Alert>
            )}

        <Box sx={{
          display: "flex", gap: "30px", flexWrap: "wrap", width: "100%", justifyContent: "flex-end"
        }}
        >
          <Button
            variant="contained"
            disabled={!disabledTable && !vehicle}
            onClick={() => (!isLoadingGps ? gpsHandler() : null)}
          >
            {isLoadingGps
              ? <CircularProgress sx={{ color: "white" }} size={20} />
              : "Пересчитать по GPS"}
          </Button>
          <Button
            variant="contained"
            disabled={!disabledTable}
            onClick={onSaveHandler}
          >
            Сохранить
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

ReportingPanel.propTypes = {
  postVehicleWeekMileagesResourceParams: PropTypes.func,
  getVehicleWeekMileagesParams: PropTypes.func,
  patchVehicleWeekMileagesResourceParams: PropTypes.func,
  getTrackPointsResourceParams: PropTypes.func,
  vehicle: PropTypes.shape({
    vehicleId: PropTypes.number,
  }),
};

ReportingPanel.defaultProps = {
  postVehicleWeekMileagesResourceParams: null,
  getVehicleWeekMileagesParams: null,
  patchVehicleWeekMileagesResourceParams: null,
  getTrackPointsResourceParams: null,
  vehicle: null,
};

export default ReportingPanel;
