import {
  Box, Stack, Typography,
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import Brightness1Icon from '@mui/icons-material/Brightness1';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SignalWifiBadIcon from '@mui/icons-material/SignalWifiBad';
import style from './PlatoonGroup.module.css';
import CarIcon from '../../../shared/ui/CarIcon/CarIcon';
import convertSecondsToTimeString from '../../../shared/utils/convertSecondsToTimeString';
import convertFormatDate from "../../../shared/utils/convertFormatDate";
import convertDurationToSeconds from '../../../shared/utils/convertDurationToSeconds';

const VEHICLE_ITEM_HEIGNT = 96;
const VEHICLE_ITEM_GAP = 10;
const LINE_SIZE = 1;

const linePosition = Math.floor(VEHICLE_ITEM_HEIGNT / 2);
const lineBottomPosition = linePosition + LINE_SIZE;
const vehicleBgSize = VEHICLE_ITEM_HEIGNT + VEHICLE_ITEM_GAP;

const wrappingGroupStyle = {
  background: `repeating-linear-gradient(
    transparent,
    transparent ${linePosition}px,
    rgb(170, 170, 170) ${linePosition}px,
    rgb(170, 170, 170) ${lineBottomPosition}px,
    transparent ${lineBottomPosition}px,
    transparent ${vehicleBgSize}px
  )`,
  gap: `${VEHICLE_ITEM_GAP}px`,
};
const wrappingVehicleStyle = { height: `${VEHICLE_ITEM_HEIGNT}px` };

// Показ Группы ТС
function PlatoonGroup({ platoonGroup, buttons }) {
  const [hover, setHover] = useState(false);
  const points = useMemo(() => {
    if (!platoonGroup) {
      return [];
    }
    const res = platoonGroup.noFinishVehicles.map(({
      id,
      simplePoint,
      num,
      intervalPrev,
      intervalNext,
      modifiedDate,
    }) => {
      const prevIntervalSeconds = convertDurationToSeconds(intervalPrev);
      const nextIntervalSeconds = convertDurationToSeconds(intervalNext);
      const now = new Date();
      const modifiedTime = new Date(simplePoint ? undefined : modifiedDate);
      const fromNow = now - modifiedTime;
      const isOlderThan2min = fromNow > 120000;
      const isOlderThan5min = fromNow > 300000;
      return ({
        id,
        simplePoint,
        num,
        intervalPrev: intervalPrev ? convertSecondsToTimeString(prevIntervalSeconds, false) : null,
        intervalNext: intervalNext ? convertSecondsToTimeString(nextIntervalSeconds, false) : null,
        hasNoData: intervalPrev === null && intervalNext === null,
        isOlderThan2min,
        isOlderThan5min,
        prevIntervalSeconds,
        nextIntervalSeconds,
      });
    }).sort((a, b) => {
      if (a.hasNoData && !b.hasNoData) {
        return -1;
      }
      if (!a.hasNoData && b.hasNoData) {
        return 1;
      }
      if (a.isOlderThan5min && !b.isOlderThan5min) {
        return -1;
      }
      if (!a.isOlderThan5min && b.isOlderThan5min) {
        return 1;
      }
      return 0;
    });
    const {
      allIntervals,
      numberOfIntervals,
    } = res.reduce((acc, item) => {
      if (item.hasNoData || item.isOlderThan5min || item.simplePoint) {
        return acc;
      }
      // TODO IRL не может быть отрицательных интевалов?! Нужно убрать Math.abs:
      return {
        allIntervals: acc.allIntervals
          + Math.abs(Number.isNaN(+item.prevIntervalSeconds) ? 0 : +item.prevIntervalSeconds),
        numberOfIntervals: acc.numberOfIntervals + (item.prevIntervalSeconds ? 1 : 0),
      };
    }, { allIntervals: 0, numberOfIntervals: 0 });
    const averageInterval = allIntervals / numberOfIntervals;
    return res.map((v) => {
      if (v.hasNoData || v.isOlderThan5min || v.simplePoint) {
        return v;
      }
      const prevIntervalRel = Math.abs(v.prevIntervalSeconds / averageInterval);
      const nextIntervalRel = Math.abs(v.nextIntervalSeconds / averageInterval);
      return ({
        ...v,
        prevColor: prevIntervalRel > 1.05 ? '#e33371' : '#0B8108',
        nextColor: nextIntervalRel > 1.05 ? '#e33371' : '#0B8108',
      });
    });
  }, [platoonGroup]);

  return (
    <Box
      className={style.platoonGroup}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {platoonGroup?.name
        ? (
          <Box className={style.headerGroup}>
            <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
              {platoonGroup.name}
              {' '}
              {`(с ${convertFormatDate(platoonGroup.startDateFrom).toFormat.slice(0, -3)}, каждые ${platoonGroup.intervalDuration})`}
            </Typography>
            <Box className={hover ? style.buttonsVisible : style.buttonsHidden}>
              {/* Взаимодействие с группой */}
              {buttons}
            </Box>
          </Box>
        )
        : null}
      <Box className={style.platoonGroupCard}>
        <Box className={style.groupVehiclesContainer}>
          <Box
            className={style.groupVehicles}
          >
            <Box className={style.groupPointsContainer}>
              <Box
                className={style.points}
                sx={wrappingGroupStyle}
              >
                {points.map((point) => (
                  <Box key={point.id} className={style.point} sx={wrappingVehicleStyle}>
                    <Box className={style.labelTop}>
                      {(point.hasNoData || point.simplePoint || point.isOlderThan5min)
                        ? null : (
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ color: point.nextColor, marginLeft: '-16px' }}
                            >
                              <ChevronLeftIcon sx={{ fontSize: '16px', visibility: point.intervalNext ? 'visible' : 'hidden' }} />
                              <Typography
                                variant="subtitle2"
                                component="div"
                                align="center"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  lineHeight: 1,
                                }}
                              >
                                {point.intervalNext}
                              </Typography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="center"
                              alignItems="center"
                              sx={{ color: point.prevColor, marginRight: '-16px' }}
                            >
                              <Typography
                                variant="subtitle2"
                                component="div"
                                align="center"
                                sx={{
                                  whiteSpace: 'nowrap',
                                  lineHeight: 1,
                                }}
                              >
                                {point.intervalPrev}
                              </Typography>
                              <ChevronRightIcon sx={{ fontSize: '16px', visibility: point.intervalPrev ? 'visible' : 'hidden' }} />
                            </Stack>
                          </Box>
                        )}
                    </Box>
                    {point.simplePoint
                      ? <Brightness1Icon color="primary" />
                      : (
                        <Box sx={{ position: 'relative' }}>
                          { point.isOlderThan2min && (
                          <Box className={style.alarmBadge} sx={{ backgroundColor: '#ffffffee' }}>
                            <SignalWifiBadIcon fontSize="12px" className={style.alarm} sx={{ color: 'error.dark' }} />
                          </Box>
                          ) }
                          <CarIcon className={[style.iconPoint, ((point.isOlderThan5min || point.hasNoData) && !point.simplePoint) ? style.expired : ''].join(' ')} />
                        </Box>
                      )}
                    <Box className={style.labelBottom}>
                      <Typography variant="subtitle2" component="div" sx={{ color: 'text.secondary' }}>
                        {point.num}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box className={style.finishVehicles}>
              {platoonGroup?.finishVehicles?.map((point) => (
                <Box key={point.id} className={style.point}>
                  <CarIcon className={style.iconPoint} />
                  <Box className={style.labelBottom}>
                    <Typography variant="subtitle2" component="div" sx={{ color: '#e33371' }}>
                      {point.num}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

PlatoonGroup.propTypes = {
  platoonGroup: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  buttons: PropTypes.element.isRequired,
};

export default PlatoonGroup;
