import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import GppGoodIcon from '@mui/icons-material/GppGood';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect } from "react";
// eslint-disable-next-line import/no-cycle
import {
  Button, Stack, TextField, ToggleButton
} from "@mui/material";
import { ORDER_STATUS, toRusError } from "./DeliveryMainWidget";
import { useAppDispatch, useAppSelector } from "../../../app/store";
import reducerPath from "../../../app/reducerPath";
import { LogisticTasksEnum, logisticTasksResourceActions } from '../../../entities/logistic/logisticTasks/redux/logisticTasks.slice';
import DeliveryReorderingComponent from './DeliveryReorderingComponent';
import styles from './DeliveryMainWidgetSecond.module.css';

function setCustomTime(dateTimeString, timeString) {
  // Проверяем, не пустая ли строка dateTimeString
  if (!dateTimeString || typeof dateTimeString !== 'string') {
    return false;
  }

  // Проверяем, не пустая ли строка timeString
  if (!timeString || typeof timeString !== 'string') {
    return false;
  }

  // Пытаемся разделить строку времени на часы и минуты
  const timeParts = timeString.split(':');

  // Проверяем, правильно ли разделено время на часы и минуты
  if (timeParts.length !== 2) {
    return false;
  }

  // Преобразуем часы и минуты в числа
  const [hours, minutes] = timeParts.map(Number);

  // Проверяем, является ли значение времени числом
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(hours) || isNaN(minutes)) {
    return false;
  }

  // Создаем новую дату из строки dateTimeString
  const date = new Date(dateTimeString);

  // Устанавливаем часы и минуты
  date.setHours(hours);
  date.setMinutes(minutes);
  date.setSeconds(0);
  date.setMilliseconds(0);

  // Возвращаем новую дату в формате ISO 8601
  return date.toISOString();
}

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: props.align || 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: props.align || 'right',
    }}
    {...props}
  />
))(({ theme, minwidth }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: `${+minwidth >= 0 ? minwidth : 180}`,
    color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function CustomizedMenus({
  buttonIcon, menuItems, selectedItemId, itemCallback
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setAnchorEl(null);
  };

  return (
    <div>
      {
        buttonIcon
          ? (
            <Button
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="text"
              disableElevation
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}
              size="small"
            >
              {buttonIcon}
            </Button>
          ) : (
            <IconButton
              id="demo-customized-button"
              aria-controls={open ? 'demo-customized-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              variant="contained"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          )
        }
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        align={menuItems?.length ? 'left' : undefined}
        minwidth={menuItems?.length ? 0 : 180}
      >
        {
          menuItems?.length ? (
            [
              <MenuItem
                key={0}
                disableRipple
                selected={!selectedItemId}
                disabled={!selectedItemId}
                onClick={(event) => {
                  itemCallback?.();
                  handleClose(event);
                }}
              >
                <Box sx={{
                  background: "grey",
                  width: "15px",
                  height: "15px",
                  marginRight: "10px",
                }}
                />
                Не выбрано
              </MenuItem>,
              ...menuItems.map((vehicle) => (
                <MenuItem
                  key={vehicle.id}
                  disableRipple
                  selected={selectedItemId === vehicle.registrationNumber}
                  disabled={selectedItemId === vehicle.registrationNumber}
                  onClick={(event) => {
                    itemCallback?.(vehicle);
                    handleClose(event);
                  }}
                >
                  <Box sx={{
                    background: vehicle.color,
                    width: "15px",
                    height: "15px",
                    marginRight: "10px",
                  }}
                  />
                  {vehicle.registrationNumber}
                </MenuItem>
              ))]
          ) : (
            [
              <MenuItem key={1} onClick={handleClose} disableRipple>
                <EditIcon />
                Редактировать
              </MenuItem>,
              <Divider key={2} sx={{ my: 0.5 }} />,
              <MenuItem key={3} sx={{ color: "red" }} onClick={handleClose} disableRipple>
                <DeleteIcon style={{ color: "red" }} />
                Удалить
              </MenuItem>

            ]
          )
        }
      </StyledMenu>
    </div>
  );
}

CustomizedMenus.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  buttonIcon: PropTypes.element,
  /* eslint-disable-next-line react/no-unused-prop-types,react/require-default-props,
  react/forbid-prop-types */
  menuItems: PropTypes.arrayOf(PropTypes.object),
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  itemCallback: PropTypes.func,
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  selectedItemId: PropTypes.string,
};

const headCells = [
  {
    id: 'id',
    numeric: false,
    disablePadding: true,
    label: 'Номер',
    width: "116px"
  },
  {
    id: 'color',
    numeric: false,
    disablePadding: true,
    label: '',
    width: "30px"
  },
  {
    id: 'loading',
    numeric: true,
    disablePadding: false,
    label: 'Погрузка',
    width: "50px"
  },
  {
    id: 'unloading',
    numeric: true,
    disablePadding: false,
    label: 'Выгрузка',
    width: "50px"
  },
  {
    id: 'addressLoading',
    numeric: false,
    disablePadding: false,
    label: 'Адрес погрузки',
    width: "162px"
  },
  {
    id: 'addressUnloading',
    numeric: false,
    disablePadding: false,
    label: 'Адрес выгрузки',
    width: "162px"
  },
  {
    id: 'weight',
    numeric: true,
    disablePadding: false,
    label: 'Вес/Объем',
    width: "142px"
  },
  {
    id: 'places',
    numeric: true,
    disablePadding: false,
    label: 'Мест',
    width: "100px"
  },
  {
    id: 'status',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    width: "116px"
  },
  {
    id: 'func',
    numeric: false,
    disablePadding: false,
    label: '',
    width: "30px"
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sx={{ width: headCell.width || null }}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar({ allErrorsLength, showReordering, setShowReordering }) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >

      <Box
        sx={{
          display: "flex", alignItems: "center", flex: "1", gap: "10px"
        }}
      >
        <Typography
          variant="subtitle1"
          id="tableTitle"
          component="div"
        >
          Доставка
        </Typography>
        <Box
          sx={{
            display: "flex", alignItems: "center", flex: "1"
          }}
        >
          {allErrorsLength
            ? (
              <Box sx={{
                display: "flex", alignItems: "center", gap: "5px", width: "max-content"
              }}
              >
                <ReportProblemIcon sx={{ color: "red" }} />
                <Typography
                  variant="subtitle1"
                  component="p"
                  sx={{ color: "red" }}
                >
                  {allErrorsLength}
                  {' '}
                  {allErrorsLength === 0
                    ? "Предупреждений"
                    : allErrorsLength <= 4
                      ? "Предупреждения"
                      : allErrorsLength >= 5
                        ? "Предупреждений"
                        : "Предупреждений" }
                </Typography>
              </Box>
            )
            : null}
        </Box>
        <Tooltip title="Изменение порядка вручную" placement="top">
          <ToggleButton
            size="small"
            selected={showReordering}
            value=""
            onChange={() => setShowReordering(!showReordering)}
          >
            <ImportExportIcon color="primary" sx={{ rotate: '90deg' }} />
          </ToggleButton>
        </Tooltip>
      </Box>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types,react/require-default-props
  allErrorsLength: PropTypes.number,
  showReordering: PropTypes.bool.isRequired,
  setShowReordering: PropTypes.func.isRequired,
};

export default function DeliveryMainWidgetSecond({
  showReordering, setShowReordering, reorderLogisticTask
}) {
  const dispatch = useAppDispatch();
  const logisticTask = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);
  const { selectedVehicles } = useAppSelector((state) => state[`${reducerPath.logisticTasksResource}/counter`]);

  const vehicles = React.useMemo(() => (
    logisticTask?.data?.sourceWithSolution?.vehicleDetailsList || []
  ), [logisticTask?.data?.sourceWithSolution?.vehicleDetailsList]);

  const orderPoints = React.useMemo(() => {
    const points = logisticTask?.data?.sourceWithSolution?.points
      ? Object.values(logisticTask?.data?.sourceWithSolution?.points) : [];
    return points.reduce((acc, p) => {
      const prevOrderObj = acc[p.orderNumber] || {};
      prevOrderObj.loading = p.isLoading ? p : prevOrderObj.loading;
      prevOrderObj.unloading = p.isUnloading ? p : prevOrderObj.unloading;
      return p.orderNumber ? { ...acc, [p.orderNumber]: prevOrderObj } : acc;
    }, {});
  }, [logisticTask?.data?.sourceWithSolution?.points]);

  const orders = React.useMemo(() => {
    const newOrders = logisticTask?.data?.sourceWithSolution?.orderSourceList || [];
    if (!selectedVehicles?.length) {
      return newOrders;
    }
    return newOrders.filter((order) => selectedVehicles?.includes(order.vehicleNumber));
  }, [logisticTask?.data?.sourceWithSolution?.orderSourceList, selectedVehicles]);
  const selectedOrders = logisticTask?.selectedOrders;

  const allErrorsLength = React.useMemo(() => orders?.reduce((prev, order) => {
    let sum = prev;
    const len = Number(order?.statuses?.length);
    if (!Number.isNaN(len)) {
      sum += len;
    }
    return sum;
  }, 0), [orders]);

  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(15);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [selected, setSelected] = React.useState([]);

  const onSelectToStore = (items) => {
    dispatch(logisticTasksResourceActions.setSelectedOrders({
      [LogisticTasksEnum.selectedOrders]: items,
    }));
  };

  useEffect(() => {
    if (!selectedOrders?.length) {
      setSelected([]);
    }
  }, [selectedOrders]);

  const handleClickRow = (row) => {
    let newSelected = [];
    if (selectedOrders.includes(row.orderNumber)) {
      newSelected = [];
    } else {
      newSelected = [row.orderNumber];
    }
    setSelected(newSelected);
    onSelectToStore(newSelected);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const assignVehicle = (vehicle, row) => {
    const newData = JSON.parse(JSON.stringify(logisticTask));
    if (vehicle) {
      if (!newData.data.solution.routes?.length) {
        newData.data.solution.routes = [];
      }
      let newRoute = newData.data.solution.routes?.find((r) => (
        r.vehicleDetails.registrationNumber === vehicle.registrationNumber
      ));
      if (!newRoute) {
        newRoute = {
          orders: [],
          vehicleDetails: vehicle,
        };
        newData.data.solution.routes.push(newRoute);
      }
      if (row.vehicleNumber) {
        // Меняем существующее ТС на другое
        const route = newData.data.solution.routes.find((r) => (
          r.vehicleDetails.registrationNumber === row.vehicleNumber
        ));
        const theOrderIndex = route.orders.findIndex((o) => (
          o.orderSource.id === row.id
        ));
        newRoute.orders.push(route.orders[theOrderIndex]);
        route.orders.splice(theOrderIndex, 1);
      } else {
        // Добавляем отсутствующее ТС
        const theOrderIndex = newData.data.solution.unallocatedOrders
          .findIndex((o) => o.orderSource.id === row.id);
        newRoute.orders
          .push(newData.data.solution.unallocatedOrders[theOrderIndex]);
        newData.data.solution.unallocatedOrders
          .splice(theOrderIndex, 1);
      }
    } else if (row.vehicleNumber) {
      // Удаляем существующее ТС
      const route = newData.data.solution.routes.find((r) => (
        r.vehicleDetails.registrationNumber === row.vehicleNumber
      ));
      const theOrderIndex = route.orders.findIndex((o) => (
        o.orderSource.id === row.id
      ));
      newData.data.solution.unallocatedOrders
        .push(route.orders[theOrderIndex]);
      route.orders.splice(theOrderIndex, 1);
    }
    dispatch(logisticTasksResourceActions.setVehicleAssigned(newData.data));
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }} elevation={1}>
        <EnhancedTableToolbar
          allErrorsLength={allErrorsLength}
          showReordering={showReordering}
          setShowReordering={setShowReordering}
        />
        { showReordering ? (
          <>
            { vehicles.filter((v) => (
              selectedVehicles?.length ? selectedVehicles.includes(v.registrationNumber) : true
            )).map((v) => (
              <DeliveryReorderingComponent
                key={v.id}
                color={v.color}
                vehicle={v}
                reorderLogisticTask={reorderLogisticTask}
              />
            ))}
          </>
        ) : (
          <>
            <TableContainer>
              <Table
                sx={{ width: "100%" }}
                aria-labelledby="tableTitle"
                size="small"
              >
                <EnhancedTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={orders.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? orders.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    : orders
                  )?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;
                    const key = `${row.id}-${index}`;
                    const isItemSelected = isSelected(row.id);

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        onClick={() => handleClickRow(row)}
                        selected={isItemSelected}
                        key={key}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox" />
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row.id}
                        </TableCell>
                        <TableCell align="right">
                          <CustomizedMenus
                            buttonIcon={(
                              <Box sx={{
                                background: row.color || "grey",
                                width: "15px",
                                height: "15px"
                              }}
                              />
                            )}
                            menuItems={vehicles}
                            selectedItemId={row.vehicleNumber}
                            itemCallback={(vehicle) => assignVehicle(vehicle, row)}
                          />
                        </TableCell>
                        <TableCell align="right">
                          {row.color ? (
                            <TextField
                              inputProps={{ style: { color: row?.statuses?.includes(ORDER_STATUS.IS_NOT_IN_TIME) ? 'red' : null } }}
                              id="startWork"
                              size="small"
                              fullWidth
                              defaultValue={row.loading?.slice("0", 5)}
                              variant="standard"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                              onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();

                                const newData = JSON.parse(JSON.stringify(logisticTask));
                                newData.data.solution.routes = newData.data
                                  .solution.routes.map((route) => {
                                    const newRoute = JSON.parse(JSON.stringify(route));
                                    newRoute.orders = newRoute.orders.map((orderCurrent) => {
                                      const newOrder = JSON.parse(JSON.stringify(orderCurrent));
                                      if (orderCurrent?.orderSource?.id === row.id) {
                                        const newDeliveryTime = setCustomTime(
                                          orderCurrent.loadingTime,
                                          event.target.value
                                        );
                                        if (newDeliveryTime) {
                                          newOrder.loadingTime = newDeliveryTime;
                                        }
                                      }
                                      return newOrder;
                                    });
                                    return newRoute;
                                  });
                                dispatch(logisticTasksResourceActions.setData(newData));
                              }}
                            />
                          ) : (
                            <>

                              <Box sx={{
                                fontSize: "10px",
                                width: "69px",
                                textAlign: "center",
                                color: "blue",
                                opacity: "0"
                              }}
                              >
                                {row.timeWindowFrom?.slice("0", 5)}
                                {' '}
                                -
                                {' '}
                                {row.timeWindowTo?.slice("0", 5)}
                                {' '}
                              </Box>
                              <TextField
                                inputProps={{ style: { color: row?.statuses?.includes(ORDER_STATUS.IS_NOT_IN_TIME) ? 'red' : null } }}
                                id="startWork"
                                size="small"
                                fullWidth
                                defaultValue={row.loading?.slice("0", 5)}
                                variant="standard"
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                                onChange={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  const newData = JSON.parse(JSON.stringify(logisticTask));
                                  newData.data.solution.unallocatedOrders = newData.data
                                    .solution.unallocatedOrders.map((orderItem) => {
                                      const orderCurrent = JSON.parse(JSON.stringify(orderItem));
                                      if (orderCurrent?.orderSource?.id === row.id) {
                                        const newDeliveryTime = setCustomTime(
                                          orderCurrent.loadingTime
                                            ? orderCurrent.loadingTime : new Date().toISOString(),
                                          event.target.value
                                        );
                                        if (newDeliveryTime) {
                                          orderCurrent.loadingTime = newDeliveryTime;
                                        }
                                      }
                                      return orderCurrent;
                                    });
                                  dispatch(logisticTasksResourceActions.setData(newData));
                                }}
                              />
                            </>

                          )}

                        </TableCell>
                        <TableCell align="left">
                          {row.color ? (
                            <TextField
                              inputProps={{ style: { color: row?.statuses?.includes(ORDER_STATUS.IS_NOT_IN_TIME) ? 'red' : null } }}
                              id="startWork"
                              size="small"
                              fullWidth
                              defaultValue={row.unloading?.slice("0", 5)}
                              variant="standard"
                              onClick={(event) => {
                                event.preventDefault();
                                event.stopPropagation();
                              }}
                              onChange={(event) => {
                                event.preventDefault();
                                event.stopPropagation();

                                const newData = JSON.parse(JSON.stringify(logisticTask));
                                newData.data.solution.routes = newData.data
                                  .solution.routes.map((route) => {
                                    const newRoute = JSON.parse(JSON.stringify(route));
                                    newRoute.orders = newRoute.orders.map((orderCurrent) => {
                                      const newOrder = JSON.parse(JSON.stringify(orderCurrent));
                                      if (orderCurrent?.orderSource?.id === row.id) {
                                        const newDeliveryTime = setCustomTime(
                                          orderCurrent.deliveryTime,
                                          event.target.value
                                        );
                                        if (newDeliveryTime) {
                                          newOrder.deliveryTime = newDeliveryTime;
                                        }
                                      }
                                      return newOrder;
                                    });
                                    return newRoute;
                                  });
                                dispatch(logisticTasksResourceActions.setData(newData));
                              }}
                            />
                          ) : (
                            <>
                              <Box sx={{
                                fontSize: "10px",
                                width: "69px",
                                textAlign: "center",
                                color: "blue"
                              }}
                              >
                                {row.timeWindowFrom?.slice("0", 5)}
                                {' '}
                                -
                                {' '}
                                {row.timeWindowTo?.slice("0", 5)}
                                {' '}
                              </Box>
                              <TextField
                                inputProps={{ style: { color: row?.statuses?.includes(ORDER_STATUS.IS_NOT_IN_TIME) ? 'red' : null } }}
                                id="startWork"
                                size="small"
                                fullWidth
                                defaultValue={row.unloading?.slice("0", 5)}
                                variant="standard"
                                onClick={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();
                                }}
                                onChange={(event) => {
                                  event.preventDefault();
                                  event.stopPropagation();

                                  const newData = JSON.parse(JSON.stringify(logisticTask));
                                  newData.data.solution.unallocatedOrders = newData.data
                                    .solution.unallocatedOrders.map((orderItem) => {
                                      const orderCurrent = JSON.parse(JSON.stringify(orderItem));

                                      if (orderCurrent?.orderSource?.id === row.id) {
                                        const newDeliveryTime = setCustomTime(
                                          orderCurrent.deliveryTime
                                            ? orderCurrent.deliveryTime : new Date().toISOString(),
                                          event.target.value
                                        );
                                        if (newDeliveryTime) {
                                          orderCurrent.deliveryTime = newDeliveryTime;
                                        }
                                      }

                                      return orderCurrent;
                                    });
                                  dispatch(logisticTasksResourceActions.setData(newData));
                                }}
                              />

                            </>

                          ) }

                        </TableCell>
                        <TableCell>
                          <Stack className={styles.address} direction="row">
                            <Box
                              className={styles.mark}
                              sx={{ backgroundColor: row.color }}
                            >
                              {orderPoints[row.orderNumber].loading.index}
                            </Box>
                            {row.addressLoading}
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Stack className={styles.address} direction="row">
                            <Box
                              className={styles.mark}
                              sx={{ backgroundColor: row.color }}
                            >
                              {orderPoints[row.orderNumber].unloading.index}
                            </Box>
                            {row.addressUnloading}
                          </Stack>
                        </TableCell>
                        <TableCell align="right">{row.weight}</TableCell>
                        <TableCell align="right">{row.places}</TableCell>
                        <Tooltip
                          arrow
                          placement="left-end"
                          title={row?.statuses?.length ? row?.statuses
                            ?.includes(ORDER_STATUS.OK) ? null : (
                              <div>
                                {row.color ? (
                                  <>
                                    <p>
                                      плановое время забора:
                                      {' '}
                                      {row.timeWindowFrom}
                                    </p>
                                    <p>
                                      плановое время доставки:
                                      {' '}
                                      {row.timeWindowTo}
                                    </p>
                                  </>
                                ) : null}
                                {row?.statuses?.map((err, i) => (
                                // eslint-disable-next-line react/no-array-index-key
                                  <p key={i}>
                                    {i + 1}
                                    {' '}
                                    {err !== ORDER_STATUS.OK ? "предупреждение" : "" }
                                    {' '}
                                    {err ? toRusError(err) : "-"}
                                  </p>
                                ))}
                              </div>
                            ) : null}
                        >
                          <TableCell align="left" sx={{ color: "red" }}>
                            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                              {row?.statuses?.length ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: 'center',
                                    gap: "5px",
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                  }}
                                >
                                  {row?.statuses?.includes(ORDER_STATUS.OK)
                                    ? (
                                      <GppGoodIcon sx={{ color: "green" }} />
                                    )
                                    : (
                                      <>
                                        {row?.statuses?.length}
                                        {' '}
                                        <ReportProblemIcon sx={{ color: "red" }} />
                                      </>
                                    )}
                                </Box>
                              ) : null}
                            </Box>

                          </TableCell>
                        </Tooltip>
                        <TableCell align="left"><CustomizedMenus /></TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 33 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[15, 50, 100]}
              component="div"
              count={orders.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Paper>
    </Box>
  );
}

DeliveryMainWidgetSecond.propTypes = {
  showReordering: PropTypes.bool.isRequired,
  setShowReordering: PropTypes.func.isRequired,
  reorderLogisticTask: PropTypes.func.isRequired,
};
