// eslint-disable-next-line no-unused-vars
import {
  Children, cloneElement,
} from "react";
import PropTypes from "prop-types";
import {
  useGetLogisticTaskIdQuery,
  usePatchLogisticTaskMutation,
  usePatchLogisticTaskSolveMutation,
} from "../redux/logisticTasks.api";
// eslint-disable-next-line boundaries/element-types
import { usePostVehicleRouteMutation } from "../../../vehicles/VehiclesRoute/vehiclesRoutes/redux/vehiclesRoutes.api";

// Компонента Бизнес уровня которая является связующим звеном между фронтом и бизнесом
// Компонента возвращает Children с новыми пропсами из бизнес уровня
function LogisticTaskBll({ children, logisticTaskId }) {
  const logisticTask = useGetLogisticTaskIdQuery({
    id: logisticTaskId
  });

  const [
    patchLogisticTask,
    resultPatchLogisticTask
  ] = usePatchLogisticTaskMutation();

  const [
    patchLogisticTaskSolve,
    solveLogisticTask
  ] = usePatchLogisticTaskSolveMutation();

  const [
    postRouteRequest,
    postRouteResult
  ] = usePostVehicleRouteMutation();

  const props = {
    logisticTask,
    getLogisticTaskSolution: ({ body }) => patchLogisticTask(
      { body: { logisticTaskId, ...body }, id: logisticTaskId }
    ),
    patchLogisticTask: ({ body }) => patchLogisticTask(
      { body: { logisticTaskId, ...body }, id: logisticTaskId }
    ),
    resultPatchLogisticTask,
    patchLogisticTaskSolve: () => patchLogisticTaskSolve(
      { id: logisticTaskId }
    ),
    postRouteRequest: ({ body }) => {
      postRouteRequest({ body });
    },
    postRouteResult: {
      ...postRouteResult
    },
    solutionLogisticTask: {
      ...resultPatchLogisticTask,
    },
    solveLogisticTask: {
      ...solveLogisticTask
    }
  };

  return logisticTask
    ? Children.toArray(children).map((child) => cloneElement(child, props))
    : null;
}

LogisticTaskBll.propTypes = {
  children: PropTypes.element.isRequired,
  logisticTaskId: PropTypes.number.isRequired,
};

export default LogisticTaskBll;
