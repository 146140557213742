import { useContext, useEffect } from "react";
import OLVectorLayer from "ol/layer/Vector";
import { Vector } from 'ol/source';
import {
  Style, Icon, Fill, Stroke, Text
} from "ol/style";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import VectorSource from 'ol/source/Vector';
import PropTypes from 'prop-types';
import CircleStyle from "ol/style/Circle";
import GeoMapContext from "../../lib/geo-map/GeoMapContext";
import vehicleIcon from '../../../assets/icons/car-red.png';
import pointIcon from '../../../assets/icons/point.png';
import { useAppSelector } from "../../../app/store";
import reducerPath from "../../../app/reducerPath";

// Задание стилей для векторного слоя
const vectorStyle = new Style({
  image: new Icon({
    scale: 0.6,
    src: vehicleIcon,
    anchor: [0.5, 1],
  }),
  text: new Text({
    font: '14px roboto',
    overflow: true,
    fill: new Fill({
      color: 'black'
    }),
    stroke: new Stroke({
      color: 'white',
      width: 3
    }),
    offsetY: -45
  })
});

// Задание стилей для векторного слоя
const vectorStyleCurrentPoint = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#1876d2',
    }),
    stroke: new Stroke({
      color: '#1876d2',
      width: 1,
    }),
    radius: 14, // Adjust the radius of the circle as needed
  }),

  text: new Text({
    font: '13px roboto',
    overflow: true,
    fill: new Fill({
      color: '#fff', // Text color
    }),
  })
});

// Задание стилей для векторного слоя
const vectorStyleRoutePoint = new Style({
  image: new Icon({
    scale: 0.6,
    src: pointIcon,
    anchor: [0.5, 1],
  }),
  text: new Text({
    font: '14px roboto',
    overflow: true,
    fill: new Fill({
      color: 'black'
    }),
    stroke: new Stroke({
      color: 'white',
      width: 3
    }),
    offsetY: -45
  })
});

// Задание стилей для векторного слоя
const vectorStyleRoutePointOnlyText = new Style({
  text: new Text({
    font: '14px roboto',
    overflow: true,
    fill: new Fill({
      color: 'black'
    }),
    stroke: new Stroke({
      color: 'white',
      width: 3
    }),
    offsetY: -25
  })
});

// Задание стилей для векторного слоя с числом внутри
const vectorStyleRoutePointNumberInside = new Style({
  image: new CircleStyle({
    fill: new Fill({
      color: '#fff',
    }),
    stroke: new Stroke({
      color: '#1876d2',
      width: 1,
    }),
    radius: 14, // Adjust the radius of the circle as needed
  }),

  text: new Text({
    font: '13px roboto',
    overflow: true,
    fill: new Fill({
      color: '#1876d2', // Text color
    }),
  })
});

// Создает и отображает маркер ТС на карте
function MarkerVectorLayer(props) {
  const {
    zIndex, coordinates, text, point, numerary, tooltipText, numeraryInAnotherLayout, title,
    isCurrentPoint
  } = props;

  // текущая карта
  const { map } = useContext(GeoMapContext);
  const isFollowMode = useAppSelector((state) => state[`${reducerPath.followMode}/counter`]).isActive;
  const zoomController = useAppSelector((state) => state[`${reducerPath.zoomController}/counter`]).zoom;

  // Создание и отображение маршрута
  useEffect(() => {
    if (!map) return undefined;

    // source для слоя по умолчанию, если координаты не переданы
    let source = new Vector({
      projection: map.getView().projection
    });

    // если переданы координаты
    if (coordinates?.length) {
      // Маркер ТС
      const markerFeature = new Feature({
        geometry: new Point(coordinates),
        tooltipText,
      });
      source = new VectorSource({
        features: [markerFeature]
      });
    }

    let newVectorLayer;
    let newVectorLayer2;

    if (numeraryInAnotherLayout && point) {
      // создание векторного слоя
      newVectorLayer = new OLVectorLayer({
        source,
        style: () => {
          const style = vectorStyleRoutePointOnlyText;
          style.getText().setText(title);
          return style;
        }
      });
      map.addLayer(newVectorLayer);
      newVectorLayer.setZIndex(zIndex);

      newVectorLayer2 = new OLVectorLayer({
        source,
        style: () => {
          const style = vectorStyleRoutePointNumberInside;
          style.getText().setText(text);
          return style;
        }
      });
      map.addLayer(newVectorLayer2);
      newVectorLayer2.setZIndex(zIndex + 1);
    } else {
      // создание векторного слоя
      newVectorLayer = new OLVectorLayer({
        source,
        style: () => {
          const style = point ? numerary
            ? vectorStyleRoutePointNumberInside
            : vectorStyleRoutePoint
            : isCurrentPoint ? vectorStyleCurrentPoint : vectorStyle;
          style.getText().setText(text);
          return style;
        }
      });
      map.addLayer(newVectorLayer);
      newVectorLayer.setZIndex(zIndex);
    }

    return () => {
      if (map) {
        map.removeLayer(newVectorLayer);
        map.removeLayer(newVectorLayer2);
      }
    };
  }, [map, coordinates, zIndex, text, point, isFollowMode, zoomController, isCurrentPoint]);

  return null;
}

MarkerVectorLayer.propTypes = {
  zIndex: PropTypes.number,
  coordinates: PropTypes.arrayOf(PropTypes.number),
  text: PropTypes.string,
  point: PropTypes.bool,
  numerary: PropTypes.bool,
  tooltipText: PropTypes.string,
  numeraryInAnotherLayout: PropTypes.bool,
  title: PropTypes.string,
  isCurrentPoint: PropTypes.bool
};

MarkerVectorLayer.defaultProps = {
  zIndex: 4,
  coordinates: [],
  text: '',
  point: false,
  numerary: null,
  tooltipText: '',
  numeraryInAnotherLayout: false,
  title: null,
  isCurrentPoint: false
};

export default MarkerVectorLayer;
