import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Alert,
  AlertTitle,
  Avatar,
  Box, Button, Grid, IconButton,
  List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, TextField, Typography
} from '@mui/material';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { useConfirm } from 'material-ui-confirm';
import CloseIcon from "@mui/icons-material/Close";
import {
  deleteGeoZone as deleteGeoZoneApi, getGeoZonesNoFormQuery, postGeoZone, putGeoZone
} from '../../shared/api/api';
import style from './CompanyGeoZonesPanel.module.css';
import GeoZonesToolbarWidget from '../../widgets/geo-zones/GeoZonesToolbarWidget';
import NoData from '../../shared/ui/NoData/NoData';
import GeoZonesMapWidget from '../../widgets/geo-zones/GeoZonesMapWidget';
import { coordsToString } from '../../shared/geo-map/lib';
import useDebounce from "../../shared/hooks/useDebounce";
import useShowError from '../../shared/lib/hooks/useShowError';

const debounceDelay = 500;
// Отображает список геозон компании и карту с полигонами зон
function CompanyGeoZonesPanel({ company }) {
  // Список геозон с сервера
  const [geoZones, setGeoZones] = useState([]);
  // Имя геозоны в текстовом поле
  const [nameGeoZone, setNameGeoZone] = useState('');
  // Координаты создаваемой геозоны
  const [coordsGeoZone, setCoordsGeoZone] = useState(null);
  // Выбранная геозона
  const [selectedGeoZone, setSelectedGeoZone] = useState(null);
  // Состояние создания полигона
  const [isCreatePolygon, setIsCreatePolygon] = useState(false);
  // Состояние создания геозоны
  const [isCreateGeoZone, setIsCreateGeoZone] = useState(false);
  // Кол-во изменений
  const [isChanged, setIsChanged] = useState(false);

  const [errors, setErrors] = useState({ name: false, polygon: false });

  const showError = useShowError();

  // Удаление геозоны
  const deleteGeoZone = () => {
    deleteGeoZoneApi(selectedGeoZone.geozoneId).then(() => {
      setGeoZones((prev) => (
        prev.filter((geoZone) => (
          geoZone.geozoneId !== selectedGeoZone.geozoneId
        ))
      ));
      setSelectedGeoZone(null);
      setNameGeoZone('');
    }).catch((error) => {
      showError(error.errorMessage);
    });
  };

  // Нажатие на геозону в списке
  const onSelectGeoZone = (geoZone) => {
    setSelectedGeoZone(geoZone);
    setNameGeoZone(geoZone.name);
    setIsCreatePolygon(false);
    setIsCreateGeoZone(false);
    setIsChanged(false);
    setErrors({ name: false, polygon: false });
  };

  // Нажатие на кнопку создание геозоны
  const onClickCreate = () => {
    setSelectedGeoZone(null);
    setCoordsGeoZone(null);
    setNameGeoZone('');
    setIsCreatePolygon((prev) => !prev);
    setIsCreateGeoZone(true);
  };

  // Нажатие на кнопку сохранения
  const save = () => {
    // Если выбрана геозона из списка, запрос на put
    if (!nameGeoZone) {
      setErrors((prev) => ({
        ...prev,
        name: true,
      }));
      return;
    }
    if (selectedGeoZone) {
      const body = {
        ...selectedGeoZone,
        name: nameGeoZone,
        area: coordsGeoZone ? coordsToString(coordsGeoZone) : selectedGeoZone.area,
      };
      putGeoZone(body)
        .then((res) => {
          const updatedGeoZone = res.data;
          setGeoZones((prev) => {
            const geoZoneIndex = prev.findIndex((gz) => gz.geozoneId === updatedGeoZone.geozoneId);
            const newGeoZones = [...prev];
            if (geoZoneIndex !== -1) {
              newGeoZones[geoZoneIndex] = updatedGeoZone;
            }
            return newGeoZones;
          });
          setIsChanged(false);
        })
        .catch((error) => { throw error; });
    } else if (isCreateGeoZone) {
      // если геозона не выбрана и состояние создания true, запрос на post
      if (!coordsGeoZone) {
        setErrors((prev) => ({
          ...prev,
          polygon: true,
        }));
        return;
      }
      const body = {
        company: { companyId: company.companyId },
        name: nameGeoZone,
        area: coordsToString(coordsGeoZone)
      };
      postGeoZone(body)
        .then((newGeoZone) => {
          setGeoZones((prev) => {
            const newGeoZones = [...prev];
            newGeoZones.push(newGeoZone);
            return newGeoZones;
          });
          setSelectedGeoZone(newGeoZone);
          setNameGeoZone(newGeoZone.name);
          setIsCreateGeoZone(false);
          setIsCreatePolygon(false);
          setIsChanged(false);
        })
        .catch((error) => { throw error; });
    }
  };

  // Сброс изменений
  const reset = () => {
    setCoordsGeoZone(null);
    if (!isCreatePolygon) {
      setSelectedGeoZone((prev) => {
        setNameGeoZone(prev.name);
        return JSON.parse(JSON.stringify(prev));
      });
    } else {
      setSelectedGeoZone(geoZones[0] || null);
      setNameGeoZone(geoZones[0]?.name || '');
    }
    setIsCreatePolygon(false);
    setIsCreateGeoZone(false);
    setErrors({ name: false, polygon: false });
    setIsChanged(false);
  };

  const getGetGeoZonesFunc = (searchCurrent) => {
    const query = { 'companyId.equals': company.companyId };
    if (searchCurrent) {
      query['name.contains'] = searchCurrent;
    }
    getGeoZonesNoFormQuery(query)
      .then((res) => {
        setGeoZones(res);
        setSelectedGeoZone(res[0]);
        setNameGeoZone(res[0]?.name || '');
      })
      .catch((error) => { throw error; });
  };

  // Загрузка геозон при смене выбранной компании в дереве
  useEffect(() => {
    if (company) {
      setIsCreateGeoZone(false);
      setIsCreatePolygon(false);
      setErrors({ name: false, polygon: false });
      setIsChanged(false);
      setNameGeoZone('');
      getGetGeoZonesFunc();
    }
  }, [company]);

  // Обновление кол-ва изменений при изменении полигона
  useEffect(() => {
    setErrors((prev) => ({
      ...prev,
      polygon: false,
    }));
    if (coordsGeoZone) {
      setIsChanged(true);
    }
  }, [coordsGeoZone]);

  const confirm = useConfirm();

  const [search, setSearch] = useState('');

  const debouncedSearch = useDebounce((value) => {
    if (value.length >= 3) {
      getGetGeoZonesFunc(value);
    } else {
      getGetGeoZonesFunc();
    }
  }, debounceDelay);

  useEffect(() => {
    debouncedSearch(search);
  }, [search, debouncedSearch]);

  const [maxItems, setMaxItems] = useState(20);

  return (
    <Box className={style.companyGeoZonesPanel}>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <GeoZonesToolbarWidget
            // onFocusName={onFocusName}
            errors={errors}
            setErrors={setErrors}
            setIsChanged={setIsChanged}
            geoZone={selectedGeoZone}
            nameGeoZone={nameGeoZone}
            onClickCreate={onClickCreate}
            isCreateGeoZone={isCreateGeoZone}
            setNameGeoZone={setNameGeoZone}
            onClickDelete={(e) => {
              confirm({
                title: 'Удаление',
                confirmationText: 'Да',
                cancellationText: 'Отмена',
                description: `Вы действительно хотите удалить «${selectedGeoZone?.name}»?`
              })
                .then(() => deleteGeoZone(e))
                .catch(() => { });
            }}
          />
          {selectedGeoZone && selectedGeoZone.polygon === null && (
            <Alert sx={{ width: "100%", marginBottom: '10px' }} severity="error">
              <AlertTitle>Ошибка</AlertTitle>
              Координаты геозоны некорректны либо отсутствуют.
            </Alert>
          )}
          <GeoZonesMapWidget
            setCoordsGeoZone={setCoordsGeoZone}
            isCreatePolygon={isCreatePolygon}
            geoZone={selectedGeoZone}
          />
          {errors.polygon
            ? (
              <Typography variant="body2" gutterBottom sx={{ color: '#D32F2F', paddingLeft: '20px' }}>
                Нарисуйте геозону перед сохранением
              </Typography>
            )
            : null}
          <Box className={style.formButtons}>
            <Button
              disabled={!isChanged && !isCreateGeoZone}
              disableElevation
              onClick={reset}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disabled={!isChanged}
              disableElevation
              onClick={save}
              variant="contained"
            >
              Сохранить
            </Button>
          </Box>
        </Grid>
        <Grid item xs={3}>
          <Paper variant="outlined">
            <Box sx={{ padding: "16px 16px 0" }}>
              <TextField
                value={search}
                onChange={(e) => { setSearch(e.target.value); }}
                id="searchThree"
                label="Поиск"
                variant="outlined"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: search.length >= 3 && (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => {
                        setSearch('');
                        getGetGeoZonesFunc();
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )
                }}
              />
            </Box>
            {geoZones.length
              ? (
                <List className={style.geoZonesList}>
                  {geoZones.map((geoZone, index) => index + 1 <= maxItems && (
                    <ListItem
                      key={geoZone.id}
                    >
                      <ListItemButton
                        selected={selectedGeoZone?.id === geoZone.id}
                        onClick={() => onSelectGeoZone(geoZone)}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <InsertPhotoIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={geoZone.name}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </List>
              )
              : (
                <NoData
                  text="Нет геозон"
                />
              )}
            {geoZones.length >= maxItems && (
            <Box sx={{ justifyContent: "center", display: "flex", marginBottom: "16px" }}>
              <Button
                variant="contained"
                onClick={() => setMaxItems((prevState) => prevState + 20)}
              >
                Показать еще
              </Button>
            </Box>
            )}

          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

CompanyGeoZonesPanel.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
};

CompanyGeoZonesPanel.defaultProps = {
  company: null,
};

export default CompanyGeoZonesPanel;
