import {
  Box, Stepper, Typography,
  Step, StepLabel
} from '@mui/material';
import React, { useMemo, useState } from 'react';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PendingIcon from '@mui/icons-material/Pending';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import { blue, red } from '@mui/material/colors';
import PropTypes from 'prop-types';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import style from './VehicleRoute.module.css';
import CarIcon from '../../../shared/ui/CarIcon/CarIcon';
import { getStatus } from './statuses';

// Показ маршрута
function VehicleRoute({ vehicleRoute, buttons }) {
  const { isBack } = vehicleRoute;
  const [hover, setHover] = useState(false);

  const statusRoute = useMemo(() => getStatus(vehicleRoute?.status), [vehicleRoute?.status]);
  return (
    <Box
      className={style.vehicleRouteContainer}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <Box className={style.headerRoute}>
        <Typography variant="h6" component="div" sx={{ color: 'text.secondary' }}>
          {vehicleRoute?.name || ''}
        </Typography>
        <Box className={hover ? style.routeButtonsVisible : style.routeButtonsHidden}>
          {/* Взаимодействие с маршрутом */}
          {buttons}
        </Box>
      </Box>
      <Box className={style.vehicleRoute}>
        <Box className={style.vehicleInfo}>
          <DirectionsCarIcon
            sx={{ color: 'text.secondary' }}
          />
          <Typography variant="subtitle2" sx={{ color: (statusRoute?.color || 'black') }}>
            {vehicleRoute?.vehicle?.name || 'Не указано'}
          </Typography>
          {vehicleRoute?.vehicle?.name !== vehicleRoute?.vehicle?.num
            ? (
              <Typography variant="body2" sx={{ color: (statusRoute?.color || 'black') }}>
                {vehicleRoute?.vehicle?.num || 'Не указано'}
              </Typography>
            )
            : null}
        </Box>
        <Stepper
          alternativeLabel
          sx={{ width: '100%' }}
        >
          {vehicleRoute.points.length ? vehicleRoute.points.map((point, i) => {
            let arriveTime = null;
            let arriveTimeUntil = null;
            // Время прибытия
            let arriveTimePeriod = null;
            if (isBack) {
              arriveTime = point.backArriveTime && new Date(point.backArriveTime);
              arriveTimeUntil = point.backArriveTimeUntil && new Date(point.backArriveTimeUntil);
            } else {
              arriveTime = point.arriveTime && new Date(point.arriveTime);
              arriveTimeUntil = point.arriveTime && new Date(point.arriveTime);
            }
            if (arriveTime && arriveTimeUntil) {
              if (arriveTime.getTime() === arriveTimeUntil.getTime()) {
                arriveTimePeriod = arriveTime.toLocaleTimeString('ru-RU').slice(0, -3);
              } else {
                arriveTimePeriod = `${arriveTime.toLocaleTimeString('ru-RU').slice(0, -3)}\u00A0– ${arriveTimeUntil.toLocaleTimeString('ru-RU').slice(0, -3)}`;
              }
            }
            // Время опоздания
            let delayTime = null;
            const nowDate = new Date();
            arriveTimeUntil = isBack
              ? vehicleRoute.points[i - 1]?.backArriveTimeUntil
              : vehicleRoute.points[i + 1]?.arriveTimeUntil;
            if (point.car && arriveTimeUntil) {
              delayTime = nowDate.getTime() - new Date(arriveTimeUntil).getTime();
            }
            // Индекс точки в реальном порядке точек (не в "отображаемых точках")
            const indexPoint = vehicleRoute.routePoints
              .findIndex((rp) => rp.vroutePointId === point.vroutePointId);
            return (
              <Step key={point.id} active>
                <StepLabel icon={point.car
                  ? (
                    <CarIcon className={isBack ? style.carBack : style.car} />
                  )
                  : (
                    <Box className={style.iconPoint}>
                      <Box className={style.additionalIcons}>
                        {/* Иконки на маршруте "туда" */}
                        {!isBack && (point.isArriveInform || point.isLeaveInform)
                          ? <InfoOutlinedIcon color="primary" fontSize="small" />
                          : null}
                        {!isBack && (point.isArriveAlert || point.isLeaveAlert)
                          ? <WarningAmberOutlinedIcon sx={{ color: red[500] }} fontSize="small" />
                          : null}
                        {!isBack && point.stopTime && point.stopTime !== point.stopTimeUntil
                          ? <LocalParkingIcon sx={{ color: blue[500] }} fontSize="small" />
                          : null}
                        {/* Иконки на маршруте "обратно" */}
                        {isBack && (point.isBackArriveInform || point.isBackLeaveInform)
                          ? <InfoOutlinedIcon color="primary" fontSize="small" />
                          : null}
                        {isBack && (point.isBackArriveAlert || point.isBackLeaveAlert)
                          ? <WarningAmberOutlinedIcon sx={{ color: red[500] }} fontSize="small" />
                          : null}
                        {isBack && point.backStopTime
                          && point.backStopTime !== point.backStopTimeUntil
                          ? <LocalParkingIcon sx={{ color: blue[500] }} fontSize="small" />
                          : null}
                      </Box>
                      {point.collapsePoint
                        ? <PendingIcon color="disabled" />
                        : (
                          <Box className={style.point}>
                            {vehicleRoute.routePoints.length
                              ? vehicleRoute.routePoints.findIndex((routePoint) => (
                                routePoint.vroutePointId === point.vroutePointId
                              )) + 1
                              : null}
                          </Box>
                        )}
                    </Box>
                  )}
                >
                  {point?.countPoints
                    ? (
                      <Typography variant="body2" component="div">
                        {point.countPoints}
                        {' '}
                        нп
                      </Typography>
                    )
                    : null}
                  {point.car && delayTime > 0
                    ? (
                      <Typography variant="subtitle2" component="div" color={red[500]}>
                        +
                        {Math.round(delayTime / 1000 / 60)}
                        {' '}
                        мин
                      </Typography>
                    )
                    : null}
                  {point?.geoName?.name
                    || point?.geoZone?.name
                    || point?.geoName?.geonameId
                    || point?.name}
                  {indexPoint === 0
                    ? (
                      <Typography variant="body2" component="div">
                        {new Date(
                          isBack ? point.backArriveTime : vehicleRoute.startTime
                        ).toLocaleDateString('ru-RU').slice(0, -5).replace('.', '/')}
                      </Typography>
                    )
                    : null}
                  {indexPoint === vehicleRoute.routePoints.length - 1
                    ? (
                      <Typography variant="body2" component="div">
                        {new Date(
                          isBack ? point.backArriveTimeUntil : vehicleRoute.finishTime
                        ).toLocaleDateString('ru-RU').slice(0, -5).replace('.', '/')}
                      </Typography>
                    )
                    : ''}
                  {arriveTimePeriod
                    ? (
                      <Typography variant="body2" component="div">
                        {arriveTimePeriod}
                      </Typography>
                    )
                    : null}
                </StepLabel>
              </Step>
            );
          })
            : null}
        </Stepper>
      </Box>
    </Box>
  );
}

VehicleRoute.propTypes = {
  vehicleRoute: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  buttons: PropTypes.element.isRequired,
};

export default VehicleRoute;
