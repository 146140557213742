import {
  Alert, AlertTitle,
  Box, Button, Checkbox, FormControlLabel, TextField
} from '@mui/material';
import React, {
  useCallback, useEffect, useMemo, useState
} from 'react';
import PropTypes from 'prop-types';
import { useConfirm } from 'material-ui-confirm';
import { useLocation, useNavigate } from 'react-router-dom';
import style from './UserFormWidget.module.css';
import {
  getImpersonazeToken,
  patchAppUser, postAppUser,
} from '../../shared/api/api';
import { usePostResetPasswordMutation } from "../../entities/account/accountResource/redux/accountResource.api";
import useAuth from '../../shared/lib/hooks/useAuth';
import { clearStorage } from '../../entities/auth/tokenResource/redux/tokenResource';

const ROLES = {
  ROLE_ADMIN: 'ROLE_ADMIN',
  ROLE_COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
  ROLE_USER: 'ROLE_USER',
};

// Поля ввода для user
const initial = {
  firstName: '',
  lastName: '',
  email: '',
  login: '',
  role: [ROLES.ROLE_USER],
};

function hasSpecialCharacters(inputString) {
  // Создаем регулярное выражение, которое будет соответствовать всем специальным символам
  const regex = /[^a-zA-Z0-9а-яА-ЯёЁ\s@._-]/;

  // Проверяем, содержит ли строка специальные символы
  return regex.test(inputString);
}

// Отображает форму заполнения инфо о юзере
function UserFormWidget(props) {
  const confirm = useConfirm();
  const {
    company, selectedUser, readOnly, isAdmin, isCompanyAdmin,
    updateTree, isFormChanged, setIsFormChanged, add
  } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const { user: authUser } = useAuth();

  // Инфо о пользователе
  const [user, setUser] = useState({ ...initial, ...selectedUser });
  // Ошибки в в форме
  const [errors, setErrors] = useState({ ...initial });
  const [isErr, setIsErr] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);

  const clearWindows = () => {
    setIsErr(false);
    setIsCorrect(false);
  };

  useEffect(() => {
    clearWindows();
  }, [selectedUser]);

  // Обработка изменений в текстовом поле
  const changeTextField = (event) => {
    clearWindows();
    setUser((prev) => ({ ...prev, [event.target.id]: event.target.value }));
    if (errors[event.target.id]) {
      setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
    }
    if (!isFormChanged) {
      setIsFormChanged(true);
    }
  };

  // Сброс полей формы
  const resetForm = useCallback((prevData) => {
    setIsFormChanged(false);
    setErrors({ ...initial });
    setUser({ ...initial, ...prevData });
  }, [setIsFormChanged]);

  const handleResetForm = (prevData) => {
    confirm({
      title: 'Отменить изменения?',
      confirmationText: 'Да',
      cancellationText: 'Нет',
      description: `Вы уверены, что хотите отменить изменения в форме?`
    })
      .then(() => {
        add(null);
        resetForm(prevData);
      });
  };

  // Нажатие на кнопку сохранения
  const saveForm = useCallback(() => {
    let isError = false;
    // Проверка полей формы на ошибки
    Object.keys(initial).forEach((key) => {
      if (!isError) {
        isError = !(user[key]);
      }
      // Показываем состояние полей формы, где есть ошибки
      setErrors((prev) => ({ ...prev, [key]: !(user[key]) }));
    });
    // Если ошибки нет, сохраняем данные
    if (!isError) {
      const body = {
        firstName: user.firstName,
        lastName: user.lastName,
      };
      // Есть appUserId => редактируем пользователя
      if (selectedUser.appUserId) {
        patchAppUser(selectedUser.appUserId, {
          appUserId: selectedUser.appUserId,
          ...body,
          email: user.email,
          role: user.role,
        }).then((upAppUser) => {
          const allUpUserInfo = {
            ...user,
            ...upAppUser,
            // название компании по putAppUsers не приходит, берем компанию из state
            company: { ...user.company },
          };
          updateTree(selectedUser.key, allUpUserInfo);
          resetForm(allUpUserInfo);
          setIsErr(false);
          setIsCorrect(true);
        })
          .catch(() => {
            setIsCorrect(false);
            setIsErr(true);
          });
      } else {
        // Нет appUserId => создаем пользователя
        postAppUser({
          ...body,
          company: { companyId: company.companyId },
          email: user.email,
          login: user.login,
          role: user.role,
        }).then((newAppUser) => {
          const allNewUserInfo = {
            ...user,
            ...newAppUser,
            // название компании по postAppUser не приходит, берем компанию из state
            company: { ...user.company },
          };
          updateTree(selectedUser.key, allNewUserInfo, true);
          resetForm(allNewUserInfo);
          setIsErr(false);
        })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error(error);
            // if (err.response?.data?.errorKey === "emailexists") {
            //   setErrors((prev) => ({ ...prev, email: true }));
            // }
            // if (err.response?.data?.errorKey === "loginexists") {
            //   setErrors((prev) => ({ ...prev, login: true }));
            // }

            if (error?.data?.title === "Email is already in use!") {
              setIsErr("Пользователь с таким Email уже зарегистрирован.");
            } else {
              setIsErr(true);
            }
          });
      }
    }
  }, [user, resetForm, updateTree, selectedUser]);

  useEffect(() => {
    setUser({
      ...initial,
      ...selectedUser,
    });
    setErrors({ ...initial });
  }, [selectedUser]);

  const isValidEmail = useMemo(() => hasSpecialCharacters(user.login), [user]);

  const [postResetPassword, { isSuccess, originalArgs }] = usePostResetPasswordMutation();

  useEffect(() => {
    if (isSuccess) {
      confirm({
        title: 'Успешно',
        confirmationText: 'Ок',
        cancellationText: null,
        description: `Отправлена ссылка для сброса пароля на адрес «${originalArgs.body}»`
      });
    }
  }, [isSuccess, confirm, originalArgs]);

  const impersonizateAvailable = useMemo(() => authUser?.authorities.includes('ROLE_ADMIN'), [authUser]);

  const postResetPasswordHandler = useCallback(() => {
    const { email } = user;
    postResetPassword({
      body: email
    });
  }, [postResetPassword, user]);

  const handleImpersonaze = useCallback(() => {
    const { user: { id: userId } } = user;
    getImpersonazeToken(userId)
      .then((res) => {
        const userToken = res.id_token;
        const atoken = window.localStorage.getItem('token') || window.sessionStorage.getItem('token');
        clearStorage();
        window.sessionStorage.setItem('atoken', atoken);
        window.sessionStorage.setItem('admin_login', authUser.login);
        window.sessionStorage.setItem('token', userToken);
        navigate('/impersonize', { state: { from: location } });
      })
      .catch((error) => {
        throw error;
      });
  }, [user]);

  return (
    <Box className={style.userFormWidget}>
      <TextField
        fullWidth
        id="firstName"
        label="Имя"
        value={user.firstName}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.firstName)}
        helperText={errors.firstName ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <TextField
        fullWidth
        id="lastName"
        label="Фамилия"
        value={user.lastName}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.lastName)}
        helperText={errors.lastName ? "Поле не может быть пустым" : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      <TextField
        fullWidth
        id="email"
        label="Эл. почта"
        value={user.email}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly
        }}
        error={Boolean(errors.email)}
        helperText={errors.email ? "Некорректный e-mail" : null}
        onBlur={(event) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9-]+.+.[A-Z]{2,4}$/i;
          setErrors((prev) => ({
            ...prev,
            [event.target.id]: !emailRegex.test(event.target.value)
          }));
        }}
      />
      <TextField
        fullWidth
        id="login"
        label="Логин"
        value={user?.login}
        onChange={changeTextField}
        required
        InputProps={{
          readOnly: Boolean(selectedUser.appUserId)
        }}
        error={Boolean(errors.login) || isValidEmail}
        helperText={errors.login ? "Поле не может быть пустым"
          : isValidEmail
            ? "Недопустимые символы"
            : null}
        onBlur={(event) => {
          setErrors((prev) => ({ ...prev, [event.target.id]: event.target.value.trim() === "" }));
        }}
      />
      {isAdmin || isCompanyAdmin
        ? (
          <FormControlLabel
            control={(
              <Checkbox
                id="role"
                checked={user.role?.includes(ROLES.ROLE_COMPANY_ADMIN)}
                color="primary"
                onChange={(e) => {
                  setIsFormChanged(true);
                  const isCompanyAdminRole = e.target.checked;
                  let newRole = [...user.role];
                  if (isCompanyAdminRole) {
                    newRole.push(ROLES.ROLE_COMPANY_ADMIN);
                  } else {
                    newRole = newRole.filter((role) => role !== ROLES.ROLE_COMPANY_ADMIN);
                  }
                  clearWindows();
                  setUser((prev) => ({ ...prev, role: newRole }));
                }}
              />
            )}
            label="Администратор компании"
          />
        )
        : null}

      {!isErr && isCorrect
          && (
          <Alert sx={{ width: "100%" }} severity="success">
            <AlertTitle>Успешно</AlertTitle>
            Пользователь был успешно сохранен.
          </Alert>
          )}
      {isErr
          && (
          <Alert sx={{ width: "100%" }} severity="error">
            <AlertTitle>Ошибка</AlertTitle>
            {typeof isErr === "string" ? isErr : "Произошла ошибка, проверьте правильность введенных данных" }
          </Alert>
          )}

      {readOnly
        ? null
        : (
          <div className={style.buttonts}>
            {impersonizateAvailable ? (
              <Box style={{ flexGrow: 1 }}>
                <Button disableElevation onClick={handleImpersonaze} variant="contained">
                  Имперсонизация
                </Button>
              </Box>
            ) : null}
            <Button
              disableElevation
              disabled={(user.login || user.lastName || user.email || user.login)
                ? !isFormChanged : false}
              onClick={() => {
                if (selectedUser.user?.login) {
                  const newSelectedUser = { ...selectedUser };
                  newSelectedUser.login = selectedUser.user.login;
                  handleResetForm(newSelectedUser);
                } else {
                  handleResetForm({ ...selectedUser });
                }
              }}
              variant="text"
            >
              Отмена
            </Button>
            <Button
              disableElevation
              disabled={!isFormChanged || isValidEmail}
              onClick={() => saveForm()}
              variant="contained"
            >
              Сохранить
            </Button>
            <Button
              style={{ display: user?.id ? "block" : "none" }}
              onClick={() => confirm({
                title: 'Сброс пароля',
                confirmationText: 'Да',
                cancellationText: 'Отмена',
                description: `Вы действительно хотите сбросить пароль пользователя «${user.firstName}»?`
              })
                .then(async () => {
                  postResetPasswordHandler();
                })}
              variant="contained"
            >
              СБРОС ПАРОЛЯ
            </Button>
          </div>
        )}
    </Box>
  );
}

UserFormWidget.propTypes = {
  company: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ])),
  selectedUser: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
    PropTypes.bool,
    PropTypes.array,
  ])),
  readOnly: PropTypes.bool,
  isAdmin: PropTypes.bool.isRequired,
  isCompanyAdmin: PropTypes.bool.isRequired,
  updateTree: PropTypes.func.isRequired,
  isFormChanged: PropTypes.bool.isRequired,
  setIsFormChanged: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
};

UserFormWidget.defaultProps = {
  selectedUser: null,
  readOnly: true,
  company: null,
};

export default UserFormWidget;
